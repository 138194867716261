import AccessGrantAction from '../../enums/AccessGrantAction.enum';
import AccessGrantReduxState from './@types/AccessGrantReduxState';
import { AnyAction } from 'redux';

const initialState: AccessGrantReduxState = {
  pageInfo: null,
};

export default function accessGrantReducer(
  state: AccessGrantReduxState = initialState,
  action: AnyAction
): AccessGrantReduxState {
  switch (action.type) {
    case AccessGrantAction.SET_ACCESS_GRANT_PAGE_INFO: {
      return {
        ...state,
        pageInfo: action.payload,
      };
    }

    case AccessGrantAction.CLEAR_ACCESS_GRANT_PAGE_INFO: {
      return {
        ...state,
        pageInfo: null,
      };
    }

    default: {
      return state;
    }
  }
}
