/* eslint-disable sort-keys */

import { JSONSchema7 } from 'json-schema';

export const mobileThemeCustomizationSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    general: {
      title: '#1 General (Mobile Web & Mobile App)',
      description: 'This is applicable for both mobile web and mobile app',
      type: 'object',
      properties: {
        'mobileWeb:label': {
          title: 'Mobile Web Section',
          type: 'object',
          description: '[general] Theme for mobile web',
          properties: {},
        },
        palette: {
          type: 'object',
          title: '#1 Palette',
          properties: {
            primary: {
              type: 'object',
              title: '#1.1 Primary',
              properties: {
                'main:enable': {
                  title: '#1.1.1 Main - primary button background color',
                  type: 'boolean',
                  description: '[general.palette.primary.main] - RGBA / RGB / Hex / Color name',
                },
                main: {
                  type: 'string',
                },
                'contrastText:enable': {
                  title: '#1.1.2 Contrast Text - primary button text color',
                  type: 'boolean',
                  description:
                    '[general.palette.primary.contrastText] - RGBA / RGB / Hex / Color name',
                },
                contrastText: {
                  type: 'string',
                },
              },
            },
            text: {
              type: 'object',
              title: '#1.2 Text',
              properties: {
                'main:enable': {
                  title: '#1.2.1 Main - text color',
                  type: 'boolean',
                  description: '[general.palette.text.main] - RGBA / RGB / Hex / Color name',
                },
                main: {
                  type: 'string',
                },
              },
            },
            'background:enable': {
              title: '#1.3 Background - background color',
              type: 'boolean',
              description: '[general.palette.background] - RGBA / RGB / Hex / Color name',
            },
            background: {
              type: 'string',
            },
          },
        },
        'actionButtonBackgroundColor:enable': {
          title: '#2 Action Button Background Color',
          type: 'boolean',
          description: '[general.actionButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        actionButtonBackgroundColor: {
          type: 'string',
        },
        'actionButtonTextColor:enable': {
          title: '#3 Action Button Text Color',
          type: 'boolean',
          description: '[general.actionButtonTextColor] - RGBA / RGB / Hex / Color name',
        },
        actionButtonTextColor: {
          type: 'string',
        },
        'actionButtonBorderColor:enable': {
          title: '#4 Action Button Border Color',
          type: 'boolean',
          description: '[general.actionButtonBorderColor] - RGBA / RGB / Hex / Color name',
        },
        actionButtonBorderColor: {
          type: 'string',
        },
        'actionButtonBorderWidth:enable': {
          title: '#4 Action Button Border Width',
          type: 'boolean',
          description: '[general.actionButtonBorderWidth]',
        },
        actionButtonBorderWidth: {
          type: 'number',
        },
        'actionButtonShadowColor:enable': {
          title: '#5 Action Button Shadow Color',
          type: 'boolean',
          description: '[general.actionButtonShadowColor] - RGBA / RGB / Hex / Color name',
        },
        actionButtonShadowColor: {
          type: 'string',
        },
        'loadingSpinnerColor:enable': {
          title: '#6 Loading Spinner Color',
          type: 'boolean',
          description: '[general.loadingSpinnerColor] - RGBA / RGB / Hex / Color name',
        },
        loadingSpinnerColor: {
          type: 'string',
        },
        requirementStatus: {
          type: 'object',
          title: '#7 Requirement Status',
          properties: {
            inprogress: {
              type: 'object',
              title: '#7.1 In-Progress',
              properties: {
                'backgroundColor:enable': {
                  title: '#7.1.1 In-Progress Status background color',
                  type: 'boolean',
                  description:
                    '[general.requirementStatus.inprogress.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'textColor:enable': {
                  title: '#7.1.2 In-Progress Status text color',
                  type: 'boolean',
                  description:
                    '[general.requirementStatus.inprogress.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            completed: {
              type: 'object',
              title: '#7.2 Completed',
              properties: {
                'backgroundColor:enable': {
                  title: '#7.2.1 Completed Status background color',
                  type: 'boolean',
                  description:
                    '[general.requirementStatus.completed.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'textColor:enable': {
                  title: '#7.2.2 Completed Status text color',
                  type: 'boolean',
                  description:
                    '[general.requirementStatus.completed.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
                'completedIconUrl:enable': {
                  title: '#7.2.3 Completed Status text color',
                  type: 'boolean',
                  description: '[general.requirementStatus.completed.completedIconUrl]',
                },
                completedIconUrl: {
                  type: 'string',
                },
              },
            },
            todo: {
              type: 'object',
              title: '#7.3 Todo',
              properties: {
                'backgroundColor:enable': {
                  title: '#7.3.1 Todo Status background color',
                  type: 'boolean',
                  description:
                    '[general.requirementStatus.todo.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'textColor:enable': {
                  title: '#7.3.2 Todo Status text color',
                  type: 'boolean',
                  description:
                    '[general.requirementStatus.todo.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
          },
        },
        'backIconUrl:enable': {
          title: '#8 Back Icon URL',
          type: 'boolean',
          description: '[general.backIconUrl]',
        },
        backIconUrl: {
          type: 'string',
        },
        button: {
          type: 'object',
          title: '#9 Button',
          properties: {
            disabled: {
              type: 'object',
              title: '#9.1 Disabled Button',
              properties: {
                'backgroundColor:enable': {
                  title: '#9.1.1 Disabled Button background color',
                  type: 'boolean',
                  description:
                    '[general.button.disabled.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'textColor:enable': {
                  title: '#9.1.2 Disabled Button text color',
                  type: 'boolean',
                  description:
                    '[general.button.disabled.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
          },
        },
        modal: {
          type: 'object',
          title: '#10 Modal',
          properties: {
            'backgroundColor:enable': {
              title: '#10.1 Modal background color',
              type: 'boolean',
              description: '[general.modal.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
          },
        },
        'errorIconUrl:enable': {
          title: '#11 Error Icon URL',
          type: 'boolean',
          description: '[general.errorIconUrl]',
        },
        errorIconUrl: {
          type: 'string',
        },
        'enableOnDesktop:enable': {
          title: '#12 Enable mobile web on desktop',
          type: 'boolean',
          description: '[general.enableOnDesktop]',
        },
        enableOnDesktop: {
          type: undefined,
          enum: [true, false],
        },
        'hideDownloadAppButton:enable': {
          title: '#13 Hide download app button (apply for the whole web)',
          type: 'boolean',
          description: '[general.hideDownloadAppButton]',
        },
        hideDownloadAppButton: {
          type: undefined,
          enum: [true, false],
        },
        'headerLogoImageUrl:enable': {
          title: '#14 Header logo image URL',
          type: 'boolean',
          description: '[general.headerLogoImageUrl]',
        },
        headerLogoImageUrl: {
          type: 'string',
        },
        'disableCheckInButton:enable': {
          title: '#15 Disable check-in button',
          type: 'boolean',
          description: '[general.disableCheckInButton]',
        },
        disableCheckInButton: {
          type: undefined,
          enum: [true, false],
        },
        'disabledCheckInTextColor:enable': {
          title: '#16 Disabled check-in text color',
          type: 'boolean',
          description: '[general.disabledCheckInTextColor] - RGBA / RGB / Hex / Color name',
        },
        disabledCheckInTextColor: {
          type: 'string',
        },
        'authenticationEnabled:enable': {
          title: '#17 Enable authentication to the user access its stay management',
          type: 'boolean',
          description: '[general.authenticationEnabled]',
        },
        authenticationEnabled: {
          type: undefined,
          enum: [true, false],
        },
        'mobileApp:label': {
          title: 'Mobile App Section',
          type: 'object',
          description: '[general] Theme for mobile app',
          properties: {},
        },
        'textColor:enable': {
          title: '#1 User details screen text color',
          type: 'boolean',
          description: '[general.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'borderColor:enable': {
          title: '#2 User details screen user card border color',
          type: 'boolean',
          description: '[general.borderColor] - RGBA / RGB / Hex / Color name',
        },
        borderColor: {
          type: 'string',
        },
        'buttonColor:enable': {
          title: '#3 User details screen button color',
          type: 'boolean',
          description: '[general.buttonColor] - RGBA / RGB / Hex / Color name',
        },
        buttonColor: {
          type: 'string',
        },
        'shadowColor:enable': {
          title: '#4 User details screen shadow color',
          type: 'boolean',
          description: '[general.shadowColor] - RGBA / RGB / Hex / Color name',
        },
        shadowColor: {
          type: 'string',
        },
        'textInputColor:enable': {
          title: '#5 User details screen text input color',
          type: 'boolean',
          description: '[general.textInputColor] - RGBA / RGB / Hex / Color name',
        },
        textInputColor: {
          type: 'string',
        },
        'backgroundColor:enable': {
          title: '#6 User details screen background color',
          type: 'boolean',
          description: '[general.backgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backgroundColor: {
          type: 'string',
        },
        'primaryTextColor:enable': {
          title: '#7 User details screen primary text color',
          type: 'boolean',
          description: '[general.primaryTextColor] - RGBA / RGB / Hex / Color name',
        },
        primaryTextColor: {
          type: 'string',
        },
        'secondaryTextColor:enable': {
          title: '#8 User details screen secondary text color',
          type: 'boolean',
          description: '[general.secondaryTextColor] - RGBA / RGB / Hex / Color name',
        },
        secondaryTextColor: {
          type: 'string',
        },
        'disabledButtonColor:enable': {
          title: '#9 User details screen disabled button color',
          type: 'boolean',
          description: '[general.disabledButtonColor] - RGBA / RGB / Hex / Color name',
        },
        disabledButtonColor: {
          type: 'string',
        },
        'logoImageUrl:enable': {
          title: '#10 Auth flow logo image URL',
          type: 'boolean',
          description: '[general.logoImageUrl]',
        },
        logoImageUrl: {
          type: 'string',
        },
        'customBackIconUrl:enable': {
          title: '#11 Auth flow custom back icon URL',
          type: 'boolean',
          description: '[general.customBackIconUrl]',
        },
        customBackIconUrl: {
          type: 'string',
        },
        'authActionButtonBackgroundColor:enable': {
          title: '#12 Auth flow action button background color',
          type: 'boolean',
          description: '[general.authActionButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        authActionButtonBackgroundColor: {
          type: 'string',
        },
        'authActionButtonBorderColor:enable': {
          title: '#13 Auth flow action button border color',
          type: 'boolean',
          description: '[general.authActionButtonBorderColor] - RGBA / RGB / Hex / Color name',
        },
        authActionButtonBorderColor: {
          type: 'string',
        },
        'authActionButtonColor:enable': {
          title: '#14 Auth flow action button text color',
          type: 'boolean',
          description: '[general.authActionButtonColor] - RGBA / RGB / Hex / Color name',
        },
        authActionButtonColor: {
          type: 'string',
        },
        'authTextInputBackgroundColor:enable': {
          title: '#15 Auth flow text input background color',
          type: 'boolean',
          description: '[general.authTextInputBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        authTextInputBackgroundColor: {
          type: 'string',
        },
        'authTextInputBorderColor:enable': {
          title: '#16 Auth flow text input border color',
          type: 'boolean',
          description: '[general.authTextInputBorderColor] - RGBA / RGB / Hex / Color name',
        },
        authTextInputBorderColor: {
          type: 'string',
        },
        'authTextInputColor:enable': {
          title: '#17 Auth flow text input text color',
          type: 'boolean',
          description: '[general.authTextInputColor] - RGBA / RGB / Hex / Color name',
        },
        authTextInputColor: {
          type: 'string',
        },
        'authButtonBackgroundColor:enable': {
          title: '#18 Auth flow button background color',
          type: 'boolean',
          description: '[general.authButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        authButtonBackgroundColor: {
          type: 'string',
        },
        'authButtonBorderColor:enable': {
          title: '#19 Auth flow button border color',
          type: 'boolean',
          description: '[general.authButtonBorderColor] - RGBA / RGB / Hex / Color name',
        },
        authButtonBorderColor: {
          type: 'string',
        },
        'authButtonColor:enable': {
          title: '#20 Auth flow button text color',
          type: 'boolean',
          description: '[general.authButtonColor] - RGBA / RGB / Hex / Color name',
        },
        authButtonColor: {
          type: 'string',
        },
        'authBackgroundColor:enable': {
          title: '#21 Auth flow background color',
          type: 'boolean',
          description: '[general.authBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        authBackgroundColor: {
          type: 'string',
        },
        'headerTextColor:enable': {
          title: '#22 Header Text Color',
          type: 'boolean',
          description: '[general.headerTextColor] - RGBA / RGB / Hex / Color name',
        },
        headerTextColor: {
          type: 'string',
        },
        'headerLogoHeight:enable': {
          title: '#23 Header logo image height',
          type: 'boolean',
          description: '[general.headerLogoHeight]',
        },
        headerLogoHeight: {
          type: 'string',
        },
        'headerLogoWidth:enable': {
          title: '#24 Header logo image width',
          type: 'boolean',
          description: '[general.headerLogoWidth]',
        },
        headerLogoWidth: {
          type: 'string',
        },
        'defaultPhonePrefix:enable': {
          title: '#25 Default phone prefix ISO code',
          type: 'boolean',
          description:
            'Default phone prefix ISO code. If not set, default is "us". [general.defaultPhonePrefix]',
        },
        defaultPhonePrefix: {
          type: 'string',
        },
        'enableGetQrCodeButton:enable': {
          title: '#26 Enable get QR code button',
          type: 'boolean',
          description: '[general.enableGetQrCodeButton]',
        },
        enableGetQrCodeButton: {
          type: undefined,
          enum: [true, false],
        },
        'displayWalletPassButton:enable': {
          title: '#27 Display wallet pass button',
          type: 'boolean',
          description: '[general.displayWalletPassButton]',
        },
        displayWalletPassButton: {
          type: undefined,
          enum: [true, false],
        },
        'downloadAppButtonUrl:enable': {
          title: '#28 Custom download app button URL (iOS & Android)',
          type: 'boolean',
          description: '[general.downloadAppButtonUrl]',
        },
        downloadAppButtonUrl: {
          type: 'string',
        },
        'downloadiOSAppButtonUrl:enable': {
          title: '#29 Custom download app button URL (iOS only)',
          type: 'boolean',
          description: '[general.downloadiOSAppButtonUrl]',
        },
        downloadiOSAppButtonUrl: {
          type: 'string',
        },
        'downloadAndroidAppButtonUrl:enable': {
          title: '#30 Custom download app button URL (Android only)',
          type: 'boolean',
          description: '[general.downloadAndroidAppButtonUrl]',
        },
        downloadAndroidAppButtonUrl: {
          type: 'string',
        },
        'disableRemoteAssistance:enable': {
          title: '#31 Disable remote assistance',
          type: 'boolean',
          description: '[general.disableRemoteAssistance]',
        },
        disableRemoteAssistance: {
          type: undefined,
          enum: [true, false],
        },
        'appGooglePlayUrl:enable': {
          title: '#32 Mobile app GooglePlay URL (force update popup)',
          type: 'boolean',
          description: '[general.appGooglePlayUrl]',
        },
        appGooglePlayUrl: {
          type: 'string',
        },
        'appAppStoreUrl:enable': {
          title: '#33 Mobile app AppStore URL (force update popup)',
          type: 'boolean',
          description: '[general.appAppStoreUrl]',
        },
        appAppStoreUrl: {
          type: 'string',
        },
        'defaultCountryCode:enable': {
          title: '#34 The default contry code to present on phone input',
          type: 'boolean',
          description: '[general.defaultCountryCode]',
        },
        defaultCountryCode: {
          type: 'string',
        },
        inputText: {
          type: 'object',
          title: '#35 Input Text',
          properties: {
            'backgroundColor:enable': {
              title: '#35.1 Input background color',
              type: 'boolean',
              description: '[general.inputText.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#35.2 Input text color',
              type: 'boolean',
              description: '[general.inputText.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'inactiveBorderColor:enable': {
              title: '#35.3 Inactive input border color',
              type: 'boolean',
              description:
                '[general.inputText.inactiveBorderColor] - RGBA / RGB / Hex / Color name',
            },
            inactiveBorderColor: {
              type: 'string',
            },
            'activeBorderColor:enable': {
              title: '#35.4 Active input border color',
              type: 'boolean',
              description: '[general.inputText.activeBorderColor] - RGBA / RGB / Hex / Color name',
            },
            activeBorderColor: {
              type: 'string',
            },
          },
        },
        actionList: {
          type: 'object',
          title: '#36 Action List',
          properties: {
            'backgroundColor:enable': {
              title: '#36.1 Item background color',
              type: 'boolean',
              description: '[general.actionList.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#36.2 Text color',
              type: 'boolean',
              description: '[general.actionList.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'borderColor:enable': {
              title: '#36.3 Border color',
              type: 'boolean',
              description: '[general.actionList.borderColor] - RGBA / RGB / Hex / Color name',
            },
            borderColor: {
              type: 'string',
            },
            'fontSize:enable': {
              title: '#36.4 Font size',
              type: 'boolean',
              description: '[general.actionList.fontSize]',
            },
            fontSize: {
              type: 'number',
            },
            'borderWidth:enable': {
              title: '#36.5 Border width',
              type: 'boolean',
              description: '[general.actionList.borderWidth]',
            },
            borderWidth: {
              type: 'number',
            },
            'borderRadius:enable': {
              title: '#36.6 Border radius',
              type: 'boolean',
              description: '[general.actionList.borderRadius]',
            },
            borderRadius: {
              type: 'number',
            },
            'elevation:enable': {
              title: '#36.7 Elevation / Border shadow',
              type: 'boolean',
              description: '[general.actionList.elevation]',
            },
            elevation: {
              type: 'number',
            },
          },
        },
      },
    },
    dashboardScreen: {
      title: '#2 Dashboard screen',
      type: 'object',
      properties: {
        'displayCheckInButton:enable': {
          title: '#1 Display check-in button',
          type: 'boolean',
          description: '[dashboardScreen.displayCheckInButton]',
        },
        displayCheckInButton: {
          type: undefined,
          enum: [true, false],
        },
        'displayCheckoutButton:enable': {
          title: '#2 Display check-out button',
          type: 'boolean',
          description: '[dashboardScreen.displayCheckoutButton]',
        },
        displayCheckoutButton: {
          type: undefined,
          enum: [true, false],
        },
        'keycardUrl:enable': {
          title: '#3 Keycard URL',
          type: 'boolean',
          description: '[dashboardScreen.keycardUrl]',
        },
        keycardUrl: {
          type: 'string',
        },
        'logoUrl:enable': {
          title: '#4 Logo URL',
          type: 'boolean',
          description: '[dashboardScreen.logoUrl]',
        },
        logoUrl: {
          type: 'string',
        },
        'displayYourStayTab:enable': {
          title: '#5 Display your stay tab',
          type: 'boolean',
          description: '[dashboardScreen.displayYourStayTab]',
        },
        displayYourStayTab: {
          type: undefined,
          enum: [true, false],
        },
        'displayServicesTab:enable': {
          title: '#6 Display services tab',
          type: 'boolean',
          description: '[dashboardScreen.displayServicesTab]',
        },
        displayServicesTab: {
          type: undefined,
          enum: [true, false],
        },
        'displayStaySection:enable': {
          title: '#7 Display stay section',
          type: 'boolean',
          description: '[dashboardScreen.displayStaySection]',
        },
        displayStaySection: {
          type: undefined,
          enum: [true, false],
        },
        'displayAmenitiesSection:enable': {
          title: '#8 Display amenities section',
          type: 'boolean',
          description: '[dashboardScreen.displayAmenitiesSection]',
        },
        displayAmenitiesSection: {
          type: undefined,
          enum: [true, false],
        },
        'displayKeycardSection:enable': {
          title: '#9 displayKeycardSection',
          type: 'boolean',
          description: '[dashboardScreen.displayKeycardSection]',
        },
        displayKeycardSection: {
          type: undefined,
          enum: [true, false],
        },
        'displayQrSection:enable': {
          title: '#10 Display QR section',
          type: 'boolean',
          description: '[dashboardScreen.displayQrSection]',
        },
        displayQrSection: {
          type: undefined,
          enum: [true, false],
        },
        'displayInfoSection:enable': {
          title: '#11 Display info section',
          type: 'boolean',
          description: '[dashboardScreen.displayInfoSection]',
        },
        displayInfoSection: {
          type: undefined,
          enum: [true, false],
        },
        'displayRoomSection:enable': {
          title: '#12 Display room section',
          type: 'boolean',
          description: '[dashboardScreen.displayRoomSection]',
        },
        displayRoomSection: {
          type: undefined,
          enum: [true, false],
        },
        'displayFoodSection:enable': {
          title: '#13 Display food section',
          type: 'boolean',
          description: '[dashboardScreen.displayFoodSection]',
        },
        displayFoodSection: {
          type: undefined,
          enum: [true, false],
        },
        'containerBackgroundColor:enable': {
          title: '#14 Container background color',
          type: 'boolean',
          description: '[dashboardScreen.containerBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        containerBackgroundColor: {
          type: 'string',
        },
        'selectReservationBackgroundColor:enable': {
          title: '#15 Select reservation background color',
          type: 'boolean',
          description:
            '[dashboardScreen.selectReservationBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        selectReservationBackgroundColor: {
          type: 'string',
        },
        'checkInIconImageUrl:enable': {
          title: '#16 Check-in icon imageUrl',
          type: 'boolean',
          description: '[dashboardScreen.checkInIconImageUrl]',
        },
        checkInIconImageUrl: {
          type: 'string',
        },
        'checkOutIconImageUrl:enable': {
          title: '#17 Check-out icon imageUrl',
          type: 'boolean',
          description: '[dashboardScreen.checkOutIconImageUrl]',
        },
        checkOutIconImageUrl: {
          type: 'string',
        },
        'checkInIconBackgroundColor:enable': {
          title: '#18 Check-in icon background color',
          type: 'boolean',
          description:
            '[dashboardScreen.checkInIconBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        checkInIconBackgroundColor: {
          type: 'string',
        },
        'checkOutIconBackgroundColor:enable': {
          title: '#19 Check-out icon background color',
          type: 'boolean',
          description:
            '[dashboardScreen.checkOutIconBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        checkOutIconBackgroundColor: {
          type: 'string',
        },
        'reservationCardFallbackImageUrl:enable': {
          title: '#20 Reservation card fallback image url',
          type: 'boolean',
          description: '[dashboardScreen.reservationCardFallbackImageUrl]',
        },
        reservationCardFallbackImageUrl: {
          type: 'string',
        },
        'inContainerTextColor:enable': {
          title: '#21 In container text color',
          type: 'boolean',
          description: '[dashboardScreen.inContainerTextColor] - RGBA / RGB / Hex / Color name',
        },
        inContainerTextColor: {
          type: 'string',
        },
        'inContainerIconTintColor:enable': {
          title: '#22 In container icon tint color',
          type: 'boolean',
          description: '[dashboardScreen.inContainerIconTintColor] - RGBA / RGB / Hex / Color name',
        },
        inContainerIconTintColor: {
          type: 'string',
        },
        'selectReservationTextColor:enable': {
          title: '#23 Select reservation text color',
          type: 'boolean',
          description:
            '[dashboardScreen.selectReservationTextColor] - RGBA / RGB / Hex / Color name',
        },
        selectReservationTextColor: {
          type: 'string',
        },
        'checkInButtonBackgroundColor:enable': {
          title: '#24 Check-in button background color',
          type: 'boolean',
          description:
            '[dashboardScreen.checkInButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        checkInButtonBackgroundColor: {
          type: 'string',
        },
        'checkInButtonTextColor:enable': {
          title: '#25 Check-in button text color',
          type: 'boolean',
          description: '[dashboardScreen.checkInButtonTextColor] - RGBA / RGB / Hex / Color name',
        },
        checkInButtonTextColor: {
          type: 'string',
        },
        'checkOutButtonBackgroundColor:enable': {
          title: '#26 Check-out button background color',
          type: 'boolean',
          description:
            '[dashboardScreen.checkOutButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        checkOutButtonBackgroundColor: {
          type: 'string',
        },
        'checkOutButtonTextColor:enable': {
          title: '#27 Check-out button text color',
          type: 'boolean',
          description: '[dashboardScreen.checkOutButtonTextColor] - RGBA / RGB / Hex / Color name',
        },
        checkOutButtonTextColor: {
          type: 'string',
        },
        'shouldHideKeycardBeforeCheckIn:enable': {
          title: '#28 Hide keycard UI before check-in',
          type: 'boolean',
          description: '[dashboardScreen.shouldHideKeycardBeforeCheckIn]',
        },
        shouldHideKeycardBeforeCheckIn: {
          type: undefined,
          enum: [true, false],
        },
        'keyCardGradientStartColor:enable': {
          title: '#29 Start color of the keycard gradient',
          type: 'boolean',
          description: '[dashboardScreen.keyCardGradientStartColor] - Hex',
        },
        keyCardGradientStartColor: {
          type: 'string',
        },
        'keyCardGradientEndColor:enable': {
          title: '#30 End color of the keycard gradient',
          type: 'boolean',
          description: '[dashboardScreen.keyCardGradientEndColor] - Hex',
        },
        keyCardGradientEndColor: {
          type: 'string',
        },
        'feedbackBoxBackgroundColor:enable': {
          title: '#31 Feedback box background color',
          type: 'boolean',
          description:
            '[dashboardScreen.feedbackBoxBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        feedbackBoxBackgroundColor: {
          type: 'string',
        },
        'feedbackTextColor:enable': {
          title: '#32 Feedback text color',
          type: 'boolean',
          description: '[dashboardScreen.feedbackTextColor] - RGBA / RGB / Hex / Color name',
        },
        feedbackTextColor: {
          type: 'string',
        },
        'additionalInfoRequired:enable': {
          title: '#33 Turn on dynamic screen pre-check-in popup',
          type: 'boolean',
          description: '[dashboardScreen.additionalInfoRequired]',
        },
        additionalInfoRequired: {
          type: undefined,
          enum: [true, false],
        },
        'stayTabDateFormat:enable': {
          title: '#34 Stay tab check-in/check-out date format',
          type: 'boolean',
          description: '[dashboardScreen.stayTabDateFormat]',
        },
        stayTabDateFormat: {
          type: 'string',
        },
        'accessGrantSelectionDateFormat:enable': {
          title: '#35 Access grant selection bottom sheet check-in date format',
          type: 'boolean',
          description: '[dashboardScreen.accessGrantSelectionDateFormat]',
        },
        accessGrantSelectionDateFormat: {
          type: 'string',
        },
        'keycardDateFormat:enable': {
          title: '#36 Digital key card expiry date format',
          type: 'boolean',
          description: '[dashboardScreen.keycardDateFormat]',
        },
        keycardDateFormat: {
          type: 'string',
        },
        'displayRoomNumberOnKeycard:enable': {
          title: '#37 Display room # on keycard',
          type: 'boolean',
          description: '[dashboardScreen.displayRoomNumberOnKeycard]',
        },
        displayRoomNumberOnKeycard: {
          type: undefined,
          enum: [true, false],
        },
        'keycardRoomNumberTextColor:enable': {
          title: '#38 Keycard room # text color',
          type: 'boolean',
          description:
            '[dashboardScreen.keycardRoomNumberTextColor] - RGBA / RGB / Hex / Color name',
        },
        keycardRoomNumberTextColor: {
          type: 'string',
        },
        'displayKeycardHeaderText:enable': {
          title: '#39 Display keycard header text',
          type: 'boolean',
          description: '[dashboardScreen.displayKeycardHeaderText]',
        },
        displayKeycardHeaderText: {
          type: undefined,
          enum: [true, false],
        },
        'keycardHeaderTextColor:enable': {
          title: '#40 Keycard header text color',
          type: 'boolean',
          description: '[dashboardScreen.keycardHeaderTextColor] - RGBA / RGB / Hex / Color name',
        },
        keycardHeaderTextColor: {
          type: 'string',
        },
        'requirementOverlayTextColor:enable': {
          title: '#41 Requirement overlay text color',
          type: 'boolean',
          description:
            '[dashboardScreen.requirementOverlayTextColor] - RGBA / RGB / Hex / Color name',
        },
        requirementOverlayTextColor: {
          type: 'string',
        },
        'requirementOverlayIconBackgroundColor:enable': {
          title: '#42 Requirement overlay icon background color',
          type: 'boolean',
          description:
            '[dashboardScreen.requirementOverlayIconBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        requirementOverlayIconBackgroundColor: {
          type: 'string',
        },
        'requirementOverlayButtonBackgroundColor:enable': {
          title: '#43 Requirement overlay button background color',
          type: 'boolean',
          description:
            '[dashboardScreen.requirementOverlayButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        requirementOverlayButtonBackgroundColor: {
          type: 'string',
        },
        'requirementOverlayButtonTextColor:enable': {
          title: '#44 Requirement overlay button text color',
          type: 'boolean',
          description:
            '[dashboardScreen.requirementOverlayButtonTextColor] - RGBA / RGB / Hex / Color name',
        },
        requirementOverlayButtonTextColor: {
          type: 'string',
        },
        'requirementOverlayIconColor:enable': {
          title: '#45 Requirement overlay icon color',
          type: 'boolean',
          description:
            '[dashboardScreen.requirementOverlayIconColor] - RGBA / RGB / Hex / Color name',
        },
        requirementOverlayIconColor: {
          type: 'string',
        },
        'inContainerDividerColor:enable': {
          title: '#46 In container divider color',
          type: 'boolean',
          description: '[dashboardScreen.inContainerDividerColor] - RGBA / RGB / Hex / Color name',
        },
        inContainerDividerColor: {
          type: 'string',
        },
        'keycardHeaderTextSize:enable': {
          title: '#47 Keycard header text size',
          type: 'boolean',
          description: '[dashboardScreen.keycardHeaderTextSize]',
        },
        keycardHeaderTextSize: {
          type: 'number',
        },
        'keycardRoomNumberTextSize:enable': {
          title: '#48 Keycard room # text size',
          type: 'boolean',
          description: '[dashboardScreen.keycardRoomNumberTextSize]',
        },
        keycardRoomNumberTextSize: {
          type: 'number',
        },
        'keycardErrorImage:enable': {
          title: '#49 Keycard error image url',
          type: 'boolean',
          description: '[dashboardScreen.keycardErrorImage]',
        },
        keycardErrorImage: {
          type: 'string',
        },
        'servicePriceTextColor:enable': {
          title: '#50 Service total price text color',
          type: 'boolean',
          description: '[dashboardScreen.servicePriceTextColor] - RGBA / RGB / Hex / Color name',
        },
        servicePriceTextColor: {
          type: 'string',
        },
        'shouldCreateKeysOnlyWhenNoValidCredentialsExist:enable': {
          title: '#51 Should create keys only when no credential exist',
          type: 'boolean',
          description: '[dashboardScreen.shouldCreateKeysOnlyWhenNoValidCredentialsExist]',
        },
        shouldCreateKeysOnlyWhenNoValidCredentialsExist: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    profileScreen: {
      title: '#3 Profile screen',
      type: 'object',
      properties: {
        'feedbackEmailUrl:enable': {
          title: '#1 Feedback email URL',
          type: 'boolean',
          description: '[profileScreen.feedbackEmailUrl]',
        },
        feedbackEmailUrl: {
          type: 'string',
        },
        'feedbackFallbackUrl:enable': {
          title: '#2 Feedback fallback URL',
          type: 'boolean',
          description: '[profileScreen.feedbackFallbackUrl]',
        },
        feedbackFallbackUrl: {
          type: 'string',
        },
        'hidePaymentSection:enable': {
          title: '#3 Hide payment section',
          type: 'boolean',
          description: '[profileScreen.hidePaymentSection]',
        },
        hidePaymentSection: {
          type: undefined,
          enum: [true, false],
        },
        'faqUrl:enable': {
          title: '#4 FAQ URL',
          type: 'boolean',
          description: '[profileScreen.faqUrl]',
        },
        faqUrl: {
          type: 'string',
        },
        'privacyUrl:enable': {
          title: '#5 Privacy URL',
          type: 'boolean',
          description: '[profileScreen.privacyUrl]',
        },
        privacyUrl: {
          type: 'string',
        },
        'tosUrl:enable': {
          title: '#6 Terms of service URL',
          type: 'boolean',
          description: '[profileScreen.tosUrl]',
        },
        tosUrl: {
          type: 'string',
        },
        'displayFaq:enable': {
          title: '#7 Display FAQ button',
          type: 'boolean',
          description: '[profileScreen.displayFaq]',
        },
        displayFaq: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    captureSecurityDepositMethodScreen: {
      title: '#4 Capture security deposit method screen',
      type: 'object',
      properties: {
        'shouldDisplayCreditCardButton:enable': {
          title: '#1 Should display credit card button',
          type: 'boolean',
          description: '[captureSecurityDepositMethodScreen.shouldDisplayCreditCardButton]',
        },
        shouldDisplayCreditCardButton: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    currentBookingScreen: {
      title: '#5 Current Booking Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        'cardBackgroundColor:enable': {
          title: '#1 Card background color',
          type: 'boolean',
          description: '[currentBookingScreen.cardBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        cardBackgroundColor: {
          type: 'string',
        },
        'cardTextColor:enable': {
          title: '#2 Card text color',
          type: 'boolean',
          description: '[currentBookingScreen.cardTextColor] - RGBA / RGB / Hex / Color name',
        },
        cardTextColor: {
          type: 'string',
        },
        requirementButton: {
          type: 'object',
          title: '#3 Requirement Button',
          properties: {
            'backgroundColor:enable': {
              title: '#3.1 Requirement Button background color',
              type: 'boolean',
              description:
                '[currentBookingScreen.requirementButton.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#3.2 Requirement Button text color',
              type: 'boolean',
              description:
                '[currentBookingScreen.requirementButton.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'borderColor:enable': {
              title: '#3.3 Requirement Button border color',
              type: 'boolean',
              description:
                '[currentBookingScreen.requirementButton.borderColor] - RGBA / RGB / Hex / Color name',
            },
            borderColor: {
              type: 'string',
            },
            'borderWidth:enable': {
              title: '#3.4 Requirement Button border width',
              type: 'boolean',
              description: '[currentBookingScreen.requirementButton.borderWidth]',
            },
            borderWidth: {
              type: 'number',
            },
            'shadowColor:enable': {
              title: '#3.5 Requirement Button shadow color',
              type: 'boolean',
              description:
                '[currentBookingScreen.requirementButton.shadowColor] - RGBA / RGB / Hex / Color name',
            },
            shadowColor: {
              type: 'string',
            },
            leftIcon: {
              type: 'object',
              title: '#3.6 Requirement Button Left Icon',
              properties: {
                'fillColor:enable': {
                  title: '#3.6.1 Requirement Button Left Icon fill color',
                  type: 'boolean',
                  description:
                    '[currentBookingScreen.requirementButton.leftIcon.fillColor] - RGBA / RGB / Hex / Color name',
                },
                fillColor: {
                  type: 'string',
                },
                'backgroundColor:enable': {
                  title: '#3.6.2 Requirement Button Left Icon background color',
                  type: 'boolean',
                  description:
                    '[currentBookingScreen.requirementButton.leftIcon.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
              },
            },
            rightIcon: {
              type: 'object',
              title: '#3.7 Requirement Button Right Icon',
              properties: {
                completed: {
                  type: 'object',
                  title: '#3.7.1 Requirement Button Right Icon Completed status',
                  properties: {
                    'fillColor:enable': {
                      title: '#3.7.1.1 Requirement Button Right Icon Completed status fill color',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.requirementButton.rightIcon.completed.fillColor] - RGBA / RGB / Hex / Color name',
                    },
                    fillColor: {
                      type: 'string',
                    },
                    'strokeColor:enable': {
                      title: '#3.7.1.2 Requirement Button Right Icon Completed status stroke color',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.requirementButton.rightIcon.completed.strokeColor] - RGBA / RGB / Hex / Color name',
                    },
                    strokeColor: {
                      type: 'string',
                    },
                    'customIconUrl:enable': {
                      title:
                        '#3.7.1.3 Requirement Button Right Icon Completed status custom icon URL',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.requirementButton.rightIcon.completed.customIconUrl]',
                    },
                    customIconUrl: {
                      type: 'string',
                    },
                  },
                },
                incompleted: {
                  type: 'object',
                  title: '#3.7.2 Requirement Button Right Icon Incompleted status',
                  properties: {
                    'fillColor:enable': {
                      title: '#3.7.2.1 Requirement Button Right Icon Incompleted status fill color',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.requirementButton.rightIcon.incompleted.fillColor] - RGBA / RGB / Hex / Color name',
                    },
                    fillColor: {
                      type: 'string',
                    },
                    'customIconUrl:enable': {
                      title:
                        '#3.7.2.2 Requirement Button Right Icon Incompleted status custom icon URL',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.requirementButton.rightIcon.incompleted.customIconUrl]',
                    },
                    customIconUrl: {
                      type: 'string',
                    },
                  },
                },
              },
            },
            'termsAndConditionIconUrl:enable': {
              title: '#3.8 Terms And Conditions icon URL',
              type: 'boolean',
              description: '[currentBookingScreen.requirementButton.termsAndConditionIconUrl]',
            },
            termsAndConditionIconUrl: {
              type: 'string',
            },
            'paymentAuthorizationIconUrl:enable': {
              title: '#3.9 Payment Authorization icon URL',
              type: 'boolean',
              description: '[currentBookingScreen.requirementButton.paymentAuthorizationIconUrl]',
            },
            paymentAuthorizationIconUrl: {
              type: 'string',
            },
            'paymentStatusIconUrl:enable': {
              title: '#3.10 Payment Status icon URL',
              type: 'boolean',
              description: '[currentBookingScreen.requirementButton.paymentStatusIconUrl]',
            },
            paymentStatusIconUrl: {
              type: 'string',
            },
            'idVerificationIconUrl:enable': {
              title: '#3.11 ID Verification icon URL',
              type: 'boolean',
              description: '[currentBookingScreen.requirementButton.idVerificationIconUrl]',
            },
            idVerificationIconUrl: {
              type: 'string',
            },
            'paymentCaptureIconUrl:enable': {
              title: '#3.12 Payment Capture icon URL',
              type: 'boolean',
              description: '[currentBookingScreen.requirementButton.paymentCaptureIconUrl]',
            },
            paymentCaptureIconUrl: {
              type: 'string',
            },
          },
        },
        incompleteRequirements: {
          type: 'object',
          title: '#4 Incomplete Requirements',
          properties: {
            'iconUrl:enable': {
              title: '#4.1 Incomplete Requirements icon URL',
              type: 'boolean',
              description: '[currentBookingScreen.incompleteRequirements.iconUrl]',
            },
            iconUrl: {
              type: 'string',
            },
          },
        },
        fastPass: {
          type: 'object',
          title: '#4 Fast Pass',
          properties: {
            'backgroundColor:enable': {
              title: '#4.1 Fast Pass background color',
              type: 'boolean',
              description:
                '[currentBookingScreen.fastPass.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#4.2 Fast Pass text color',
              type: 'boolean',
              description:
                '[currentBookingScreen.fastPass.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'successTextColor:enable': {
              title: '#4.1 Fast Pass success text color',
              type: 'boolean',
              description:
                '[currentBookingScreen.fastPass.successTextColor] - RGBA / RGB / Hex / Color name',
            },
            successTextColor: {
              type: 'string',
            },
            'successIconUrl:enable': {
              title: '#4.1 Fast Pass success icon URL',
              type: 'boolean',
              description: '[currentBookingScreen.fastPass.successIconUrl]',
            },
            successIconUrl: {
              type: 'string',
            },
          },
        },
        'cardDividerColor:enable': {
          title: '#5 Card divider color',
          type: 'boolean',
          description: '[currentBookingScreen.cardDividerColor] - RGBA / RGB / Hex / Color name',
        },
        cardDividerColor: {
          type: 'string',
        },
        cardShadow: {
          type: 'object',
          title: '#6 Card shadow',
          properties: {
            'shadowColor:enable': {
              title: '#6.1 Card shadow color',
              type: 'boolean',
              description:
                '[currentBookingScreen.cardShadow.shadowColor] - RGBA / RGB / Hex / Color name',
            },
            shadowColor: {
              type: 'string',
            },
            shadowOffset: {
              type: 'object',
              title: '#6.2 Shadow offset',
              properties: {
                'width:enable': {
                  title: '#6.2.1 Shadow offset width',
                  type: 'boolean',
                  description: '[currentBookingScreen.cardShadow.shadowOffset.width]',
                },
                width: {
                  type: 'number',
                },
                'height:enable': {
                  title: '#6.2.2 Shadow offset height',
                  type: 'boolean',
                  description: '[currentBookingScreen.cardShadow.shadowOffset.height]',
                },
                height: {
                  type: 'number',
                },
              },
            },
            'shadowOpacity:enable': {
              title: '#6.3 Shadow opacity',
              type: 'boolean',
              description: '[currentBookingScreen.cardShadow.shadowOpacity] - range from 0 to 1',
            },
            shadowOpacity: {
              type: 'number',
            },
            'shadowRadius:enable': {
              title: '#6.4 Shadow radius',
              type: 'boolean',
              description: '[currentBookingScreen.cardShadow.shadowRadius]',
            },
            shadowRadius: {
              type: 'number',
            },
            'elevation:enable': {
              title: '#6.5 Elevation',
              type: 'boolean',
              description: '[currentBookingScreen.cardShadow.elevation]',
            },
            elevation: {
              type: 'number',
            },
          },
        },
        'dateFormat:enable': {
          title: '#7 Card date format',
          type: 'boolean',
          description: '[currentBookingScreen.dateFormat]',
        },
        dateFormat: {
          type: 'string',
        },
        'cardTitleColor:enable': {
          title: '#8 Card title color',
          type: 'boolean',
          description: '[currentBookingScreen.cardTitleColor] - RGBA / RGB / Hex / Color name',
        },
        cardTitleColor: {
          type: 'string',
        },
        'cardSubTitleColor:enable': {
          title: '#9 Card subtitle color',
          type: 'boolean',
          description: '[currentBookingScreen.cardSubTitleColor] - RGBA / RGB / Hex / Color name',
        },
        cardSubTitleColor: {
          type: 'string',
        },
        'hideDownloadAppButton:enable': {
          title: '#10 Hide download app button (specific to current booking screen only)',
          type: 'boolean',
          description: '[currentBookingScreen.hideDownloadAppButton]',
        },
        hideDownloadAppButton: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    idVerificationInitScreen: {
      title: '#6 ID Verification Initialize Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        selectCountry: {
          type: 'object',
          title: '#1 Select Country Picker',
          properties: {
            'backgroundColor:enable': {
              title: '#1.1 Select Country Picker background color',
              type: 'boolean',
              description:
                '[idVerificationInitScreen.selectCountry.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#1.2 Select Country Picker text color',
              type: 'boolean',
              description:
                '[idVerificationInitScreen.selectCountry.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'changeButtonBackgroundColor:enable': {
              title: '#1.3 Select Country Picker Change Button background color',
              type: 'boolean',
              description:
                '[idVerificationInitScreen.selectCountry.changeButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
            },
            changeButtonBackgroundColor: {
              type: 'string',
            },
            'changeButtonTextColor:enable': {
              title: '#1.4 Select Country Picker Change Button text color',
              type: 'boolean',
              description:
                '[idVerificationInitScreen.selectCountry.changeButtonTextColor] - RGBA / RGB / Hex / Color name',
            },
            changeButtonTextColor: {
              type: 'string',
            },
          },
        },
        'passportIconUrl:enable': {
          title: '#2 Passport icon URL',
          type: 'boolean',
          description: '[idVerificationInitScreen.passportIconUrl]',
        },
        passportIconUrl: {
          type: 'string',
        },
        'idCardIconUrl:enable': {
          title: '#3 ID Card icon URL',
          type: 'boolean',
          description: '[idVerificationInitScreen.idCardIconUrl]',
        },
        idCardIconUrl: {
          type: 'string',
        },
        'driverLicenseIconUrl:enable': {
          title: '#4 Driver License icon URL',
          type: 'boolean',
          description: '[idVerificationInitScreen.driverLicenseIconUrl]',
        },
        driverLicenseIconUrl: {
          type: 'string',
        },
        consent: {
          type: 'object',
          title: '#5 Consent',
          properties: {
            'closeIconUrl:enable': {
              title: '#5.1 Consent popup close icon URL',
              type: 'boolean',
              description: '[idVerificationInitScreen.consent.closeIconUrl]',
            },
            closeIconUrl: {
              type: 'string',
            },
            'privacyPolicyUrl:enable': {
              title: '#5.2 Consent popup privacy policy URL',
              type: 'boolean',
              description: '[idVerificationInitScreen.consent.privacyPolicyUrl]',
            },
            privacyPolicyUrl: {
              type: 'string',
            },
            'buttonBackgroundColor:enable': {
              title: '#5.3 Consent popup Button background color',
              type: 'boolean',
              description:
                '[idVerificationInitScreen.consent.buttonBackgroundColor] - RGBA / RGB / Hex / Color name',
            },
            buttonBackgroundColor: {
              type: 'string',
            },
            'buttonTextColor:enable': {
              title: '#5.4 Consent popup Button text color',
              type: 'boolean',
              description:
                '[idVerificationInitScreen.consent.buttonTextColor] - RGBA / RGB / Hex / Color name',
            },
            buttonTextColor: {
              type: 'string',
            },
          },
        },
      },
    },
    idVerificationScanDocumentScreen: {
      title: '#7 ID Verification Scan Document Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        switchCameraButton: {
          type: 'object',
          title: '#1 Switch Camera Button',
          properties: {
            'backgroundColor:enable': {
              title: '#1.1 Switch Camera Button background color',
              type: 'boolean',
              description:
                '[idVerificationScanDocumentScreen.switchCameraButton.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#1.2 Switch Camera Button text color',
              type: 'boolean',
              description:
                '[idVerificationScanDocumentScreen.switchCameraButton.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'borderColor:enable': {
              title: '#1.3 Switch Camera Button border color',
              type: 'boolean',
              description:
                '[idVerificationScanDocumentScreen.switchCameraButton.borderColor] - RGBA / RGB / Hex / Color name',
            },
            borderColor: {
              type: 'string',
            },
          },
        },
        idCard: {
          type: 'object',
          title: '#2 ID Card',
          properties: {
            front: {
              type: 'object',
              title: '#2.1 Front of ID Card',
              properties: {
                'imageUrl:enable': {
                  title: '#2.1.1 ID Card Front instruction image URL',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.front.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
              },
            },
            back: {
              type: 'object',
              title: '#2.2 Back of ID Card',
              properties: {
                'imageUrl:enable': {
                  title: '#2.2.1 ID Card Back instruction image URL',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.back.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
              },
            },
            face: {
              type: 'object',
              title: '#2.3 ID Card Face Capture',
              properties: {
                'imageUrl:enable': {
                  title: '#2.3.1 ID Card Face capture instruction image URL',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.face.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
              },
            },
          },
        },
        passport: {
          type: 'object',
          title: '#3 Passport',
          properties: {
            front: {
              type: 'object',
              title: '#3.1 Front of Passport',
              properties: {
                'imageUrl:enable': {
                  title: '#3.1.1 Front of Passport instruction image URL',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.passport.front.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
              },
            },
            face: {
              type: 'object',
              title: '#3.2 Passport Face Capture',
              properties: {
                'imageUrl:enable': {
                  title: '#3.2.1 Passport Face capture instruction image URL',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.passport.face.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
              },
            },
          },
        },
        driverLicense: {
          type: 'object',
          title: '#4 Driver License',
          properties: {
            front: {
              type: 'object',
              title: '#4.1 Front of Driver License',
              properties: {
                'imageUrl:enable': {
                  title: '#4.1.1 Driver License Front instruction image URL',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.front.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
              },
            },
            back: {
              type: 'object',
              title: '#4.2 Back of Driver License',
              properties: {
                'imageUrl:enable': {
                  title: '#4.2.1 Driver License Back instruction image URL',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.back.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
              },
            },
            face: {
              type: 'object',
              title: '#4.3 Driver License Face Capture',
              properties: {
                'imageUrl:enable': {
                  title: '#4.3.1 Driver License Face capture instruction image URL',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.face.imageUrl]',
                },
                imageUrl: {
                  type: 'string',
                },
              },
            },
          },
        },
        'enableFaceLiveness:enable': {
          title: '#5 Display direct message button',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.enableFaceLiveness]',
        },
        enableFaceLiveness: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    idVerificationResultScreen: {
      title: '#8 ID Verification Result Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        idVerificationSuccess: {
          type: 'object',
          title: '#1 ID Verification Success status',
          properties: {
            'iconUrl:enable': {
              title: '#1.1 ID Verification Success status icon URL',
              type: 'boolean',
              description: '[idVerificationResultScreen.idVerificationSuccess.iconUrl]',
            },
            iconUrl: {
              type: 'string',
            },
          },
        },
      },
    },
    paymentStatusScreen: {
      title: '#9 Payment Status Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        'visaIconUrl:enable': {
          title: '#1 Visa icon URL',
          type: 'boolean',
          description: '[paymentStatusScreen.visaIconUrl]',
        },
        visaIconUrl: {
          type: 'string',
        },
        'mastercardIconUrl:enable': {
          title: '#2 Mastercard icon URL',
          type: 'boolean',
          description: '[paymentStatusScreen.mastercardIconUrl]',
        },
        mastercardIconUrl: {
          type: 'string',
        },
        'amexIconUrl:enable': {
          title: '#3 Amex icon URL',
          type: 'boolean',
          description: '[paymentStatusScreen.amexIconUrl]',
        },
        amexIconUrl: {
          type: 'string',
        },
        'applepayIconUrl:enable': {
          title: '#4 ApplePay icon URL',
          type: 'boolean',
          description: '[paymentStatusScreen.applepayIconUrl]',
        },
        applepayIconUrl: {
          type: 'string',
        },
        inputText: {
          type: 'object',
          title: '#5 Input Text',
          properties: {
            'backgroundColor:enable': {
              title: '#5.1 Input Text background color',
              type: 'boolean',
              description:
                '[paymentStatusScreen.inputText.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'hintTextColor:enable': {
              title: '#5.2 Input Text hint text color',
              type: 'boolean',
              description:
                '[paymentStatusScreen.inputText.hintTextColor] - RGBA / RGB / Hex / Color name',
            },
            hintTextColor: {
              type: 'string',
            },
            'inputTextColor:enable': {
              title: '#5.3 Input Text color',
              type: 'boolean',
              description:
                '[paymentStatusScreen.inputText.inputTextColor] - RGBA / RGB / Hex / Color name',
            },
            inputTextColor: {
              type: 'string',
            },
          },
        },
        success: {
          type: 'object',
          title: '#6 Success Status',
          properties: {
            'iconUrl:enable': {
              title: '#6.1 Success status icon URL',
              type: 'boolean',
              description: '[idVerificationScanDocumentScreen.success.iconUrl]',
            },
            iconUrl: {
              type: 'string',
            },
          },
        },
        fail: {
          type: 'object',
          title: '#7 Fail Status',
          properties: {
            'iconUrl:enable': {
              title: '#7.1 Fail status icon URL',
              type: 'boolean',
              description: '[idVerificationScanDocumentScreen.fail.iconUrl]',
            },
            iconUrl: {
              type: 'string',
            },
          },
        },
        'hideVisaIcon:enable': {
          title: '#8 Hide Visa icon',
          type: 'boolean',
          description: '[paymentStatusScreen.hideVisaIcon]',
        },
        hideVisaIcon: {
          type: undefined,
          enum: [true, false],
        },
        'hideMastercardIcon:enable': {
          title: '#9 Hide Mastercard icon',
          type: 'boolean',
          description: '[paymentStatusScreen.hideMastercardIcon]',
        },
        hideMastercardIcon: {
          type: undefined,
          enum: [true, false],
        },
        'hideAmexIcon:enable': {
          title: '#10 Hide Amex icon',
          type: 'boolean',
          description: '[paymentStatusScreen.hideAmexIcon]',
        },
        hideAmexIcon: {
          type: undefined,
          enum: [true, false],
        },
        'hideApplepayIcon:enable': {
          title: '#11 Hide ApplePay icon',
          type: 'boolean',
          description: '[paymentStatusScreen.hideApplepayIcon]',
        },
        hideApplepayIcon: {
          type: undefined,
          enum: [true, false],
        },
        'cardIconUrlList:enable': {
          title: '#12 List of Card icon URL',
          type: 'boolean',
          description:
            '[paymentStatusScreen.cardIconUrlList] - if this is enable the default card will be hidden automatically',
        },
        cardIconUrlList: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
    paymentAuthorizationSuccessScreen: {
      title: '#10 Payment Authorization Success Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        'iconUrl:enable': {
          title: '#1 Success icon URL',
          type: 'boolean',
          description: '[paymentAuthorizationSuccessScreen.iconUrl]',
        },
        iconUrl: {
          type: 'string',
        },
      },
    },
    paymentAuthorizationErrorScreen: {
      title: '#11 Payment Authorization Error Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        'iconUrl:enable': {
          title: '#1 Error icon URL',
          type: 'boolean',
          description: '[paymentAuthorizationErrorScreen.iconUrl]',
        },
        iconUrl: {
          type: 'string',
        },
      },
    },
    termsAndConditionsScreen: {
      title: '#12 Terms And Conditions Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        signatureBox: {
          type: 'object',
          title: '#1 Signature Box',
          properties: {
            'backgroundColor:enable': {
              title: '#1.1 Signature Box background color',
              type: 'boolean',
              description:
                '[termsAndConditionsScreen.signatureBox.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'borderColor:enable': {
              title: '#1.2 Signature Box border color',
              type: 'boolean',
              description:
                '[termsAndConditionsScreen.signatureBox.borderColor] - RGBA / RGB / Hex / Color name',
            },
            borderColor: {
              type: 'string',
            },
            'hintTextColor:enable': {
              title: '#1.3 Signature Box hint color',
              type: 'boolean',
              description:
                '[termsAndConditionsScreen.signatureBox.hintTextColor] - RGBA / RGB / Hex / Color name',
            },
            hintTextColor: {
              type: 'string',
            },
            'penColor:enable': {
              title: '#1.4 Signature Box pen color',
              type: 'boolean',
              description:
                '[termsAndConditionsScreen.signatureBox.penColor] - RGBA / RGB / Hex / Color name',
            },
            penColor: {
              type: 'string',
            },
          },
        },
        clearButton: {
          type: 'object',
          title: '#2 Clear Button',
          properties: {
            'backgroundColor:enable': {
              title: '#2.1 Clear Button background color',
              type: 'boolean',
              description:
                '[termsAndConditionsScreen.clearButton.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#2.2 Clear Button text color',
              type: 'boolean',
              description:
                '[termsAndConditionsScreen.clearButton.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'borderColor:enable': {
              title: '#2.3 Clear Button border color',
              type: 'boolean',
              description:
                '[termsAndConditionsScreen.clearButton.borderColor] - RGBA / RGB / Hex / Color name',
            },
            borderColor: {
              type: 'string',
            },
          },
        },
        success: {
          type: 'object',
          title: '#3 Success Status',
          properties: {
            'iconUrl:enable': {
              title: '#3.1 Success status icon URL',
              type: 'boolean',
              description: '[termsAndConditionsScreen.success.iconUrl]',
            },
            iconUrl: {
              type: 'string',
            },
          },
        },
        fail: {
          type: 'object',
          title: '#4 Fail Status',
          properties: {
            'iconUrl:enable': {
              title: '#4.1 Fail status icon URL',
              type: 'boolean',
              description: '[termsAndConditionsScreen.fail.iconUrl]',
            },
            iconUrl: {
              type: 'string',
            },
          },
        },
      },
    },
    successScreen: {
      title: '#13 Success Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        'successIconUrl:enable': {
          title: '#1 Success icon URL',
          type: 'boolean',
          description: '[successScreen.successIconUrl]',
        },
        successIconUrl: {
          type: 'string',
        },
        'closeIconUrl:enable': {
          title: '#2 Close icon URL',
          type: 'boolean',
          description: '[successScreen.closeIconUrl]',
        },
        closeIconUrl: {
          type: 'string',
        },
        'hideQRCode:enable': {
          title: '#3 Hide QR Code',
          type: 'boolean',
          description: '[successScreen.hideQRCode]',
        },
        hideQRCode: {
          type: undefined,
          enum: [true, false],
        },
        'bottomStickyBackgroundColor:enable': {
          title: '#4 Bottom Sticky background color',
          type: 'boolean',
          description:
            '[successScreen.bottomStickyBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        bottomStickyBackgroundColor: {
          type: 'string',
        },
        'titleColor:enable': {
          title: '#5 Title color',
          type: 'boolean',
          description: '[successScreen.titleColor] - RGBA / RGB / Hex / Color name',
        },
        titleColor: {
          type: 'string',
        },
        'walletIconUrl:enable': {
          title: '#6 Wallet button icon URL',
          type: 'boolean',
          description: '[successScreen.walletIconUrl]',
        },
        walletIconUrl: {
          type: 'string',
        },
        'walletIconPosition:enable': {
          title: '#7 Wallet button icon position',
          type: 'boolean',
          description: '[successScreen.walletIconPosition]',
        },
        walletIconPosition: {
          type: 'string',
          enum: ['left', 'right'],
        },
        'hideDownloadAppButton:enable': {
          title: '#8 Hide download app button (specific to success screen only)',
          type: 'boolean',
          description: '[successScreen.hideDownloadAppButton]',
        },
        hideDownloadAppButton: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    userDetailsScreen: {
      title: '#14 User Details Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        resources: {
          type: 'object',
          title: '#1 Resources (Room)',
          properties: {
            'backgroundColor:enable': {
              title: '#1.1 Resources background color',
              type: 'boolean',
              description:
                '[userDetailsScreen.resources.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            card: {
              type: 'object',
              title: '#1.2 Resources Card',
              properties: {
                'backgroundColor:enable': {
                  title: '#1.2.1 Resources Card background color',
                  type: 'boolean',
                  description:
                    '[userDetailsScreen.resources.card.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'primaryTextColor:enable': {
                  title: '#1.2.2 Resources Card primary text color',
                  type: 'boolean',
                  description:
                    '[userDetailsScreen.resources.card.primaryTextColor] - RGBA / RGB / Hex / Color name',
                },
                primaryTextColor: {
                  type: 'string',
                },
                'secondaryTextColor:enable': {
                  title: '#1.2.3 Resources Card secondary text color',
                  type: 'boolean',
                  description:
                    '[userDetailsScreen.resources.card.secondaryTextColor] - RGBA / RGB / Hex / Color name',
                },
                secondaryTextColor: {
                  type: 'string',
                },
              },
            },
          },
        },
        header: {
          type: 'object',
          title: '#2 Header',
          properties: {
            'backgroundColor:enable': {
              title: '#2.1 Header background color',
              type: 'boolean',
              description:
                '[userDetailsScreen.header.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#2.2 Header text color',
              type: 'boolean',
              description: '[userDetailsScreen.header.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
          },
        },
        'roomInfoVisible:enable': {
          title: '#3 Room Info Visible',
          type: 'boolean',
          description: '[userDetailsScreen.roomInfoVisible]',
        },
        roomInfoVisible: {
          type: undefined,
          enum: [true, false],
        },
        'initialBackgroundColor:enable': {
          title: '#4 Initial icon background color',
          type: 'boolean',
          description: '[userDetailsScreen.initialBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        initialBackgroundColor: {
          type: 'string',
        },
        'disableResourceRate:enable': {
          title: '#5 Disable Resource (Room) Rate',
          type: 'boolean',
          description: '[userDetailsScreen.disableResourceRate]',
        },
        disableResourceRate: {
          type: undefined,
          enum: [true, false],
        },
        'disableUsersEditButton:enable': {
          title: '#6 Disable Users Edit Button',
          type: 'boolean',
          description: '[userDetailsScreen.disableUsersEditButton]',
        },
        disableUsersEditButton: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    roomNotReadyScreen: {
      title: '#15 Room Not Ready Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        'closeIconUrl:enable': {
          title: '#1 Close icon URL',
          type: 'boolean',
          description: '[roomNotReadyScreen.closeIconUrl]',
        },
        closeIconUrl: {
          type: 'string',
        },
        'iconUrl:enable': {
          title: '#2 Main icon URL',
          type: 'boolean',
          description: '[roomNotReadyScreen.iconUrl]',
        },
        iconUrl: {
          type: 'string',
        },
      },
    },
    packagesScreen: {
      title: '#16 Packages Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      type: 'object',
      properties: {
        categoryButton: {
          type: 'object',
          title: '#1 Category Button',
          properties: {
            selected: {
              type: 'object',
              title: '#1.1 Category Button Selected state',
              properties: {
                'backgroundColor:enable': {
                  title: '#1.1.1 Category Button Selected state background color',
                  type: 'boolean',
                  description:
                    '[packagesScreen.categoryButton.selected.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'textColor:enable': {
                  title: '#1.1.2 Category Button Selected state text color',
                  type: 'boolean',
                  description:
                    '[packagesScreen.categoryButton.selected.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            unselected: {
              type: 'object',
              title: '#1.2 Category Button Unselected state',
              properties: {
                'backgroundColor:enable': {
                  title: '#1.2.1 Category Button Unselected state background color',
                  type: 'boolean',
                  description:
                    '[packagesScreen.categoryButton.unselected.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'textColor:enable': {
                  title: '#1.2.2 Category Button Unselected state text color',
                  type: 'boolean',
                  description:
                    '[packagesScreen.categoryButton.unselected.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
          },
        },
        card: {
          type: 'object',
          title: '#2 Packages Card',
          properties: {
            'backgroundColor:enable': {
              title: '#2.1 Packages Card background color',
              type: 'boolean',
              description: '[packagesScreen.card.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'primaryTextColor:enable': {
              title: '#2.2 Packages Card primary text color',
              type: 'boolean',
              description: '[packagesScreen.card.primaryTextColor] - RGBA / RGB / Hex / Color name',
            },
            primaryTextColor: {
              type: 'string',
            },
            'highlightTextColor:enable': {
              title: '#2.3 Packages Card highlight text color',
              type: 'boolean',
              description:
                '[packagesScreen.card.highlightTextColor] - RGBA / RGB / Hex / Color name',
            },
            highlightTextColor: {
              type: 'string',
            },
            addButton: {
              type: 'object',
              title: '#2.4 Packages Card Add Button',
              properties: {
                'backgroundColor:enable': {
                  title: '#2.4.1 Packages Card Add Button background color',
                  type: 'boolean',
                  description:
                    '[packagesScreen.card.addButton.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'textColor:enable': {
                  title: '#2.4.2 Packages Card Add Button text color',
                  type: 'boolean',
                  description:
                    '[packagesScreen.card.addButton.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
                quantity: {
                  type: 'object',
                  title: '#2.4.3 Packages Card Add Button Quantity Section',
                  properties: {
                    'backgroundColor:enable': {
                      title: '#2.4.3.1 Packages Card Add Button Quantity Section background color',
                      type: 'boolean',
                      description:
                        '[packagesScreen.card.addButton.quantity.backgroundColor] - RGBA / RGB / Hex / Color name',
                    },
                    backgroundColor: {
                      type: 'string',
                    },
                    'textColor:enable': {
                      title: '#2.4.3.2 Packages Card Add Button Quantity Section text color',
                      type: 'boolean',
                      description:
                        '[packagesScreen.card.addButton.quantity.textColor] - RGBA / RGB / Hex / Color name',
                    },
                    textColor: {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },
        primaryButton: {
          type: 'object',
          title: '#3 Primary Button',
          properties: {
            'backgroundColor:enable': {
              title: '#3.1 Primary Button background color',
              type: 'boolean',
              description:
                '[packagesScreen.primaryButton.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#3.2 Primary Button text color',
              type: 'boolean',
              description:
                '[packagesScreen.primaryButton.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'buttonType:enable': {
              title: '#3.3 Button Type',
              type: 'boolean',
              description: '[packagesScreen.primaryButton.buttonType]',
            },
            buttonType: {
              type: 'string',
              enum: ['primary', 'outline', 'text'],
            },
          },
        },
        secondaryButton: {
          type: 'object',
          title: '#4 Secondary Button',
          properties: {
            'backgroundColor:enable': {
              title: '#4.1 Secondary Button background color',
              type: 'boolean',
              description:
                '[packagesScreen.secondaryButton.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#4.2 Secondary Button text color',
              type: 'boolean',
              description:
                '[packagesScreen.secondaryButton.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'buttonType:enable': {
              title: '#4.3 Secondary Type',
              type: 'boolean',
              description: '[packagesScreen.secondaryButton.buttonType]',
            },
            buttonType: {
              type: 'string',
              enum: ['primary', 'outline', 'text'],
            },
          },
        },
        modal: {
          type: 'object',
          title: '#5 Bottom Sheet (Modal)',
          properties: {
            'backgroundColor:enable': {
              title: '#5.1 Bottom Sheet background color',
              type: 'boolean',
              description: '[packagesScreen.modal.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'primaryTextColor:enable': {
              title: '#5.2 Bottom Sheet primary text color',
              type: 'boolean',
              description:
                '[packagesScreen.modal.primaryTextColor] - RGBA / RGB / Hex / Color name',
            },
            primaryTextColor: {
              type: 'string',
            },
            'secondaryTextColor:enable': {
              title: '#5.3 Bottom Sheet secondary text color',
              type: 'boolean',
              description:
                '[packagesScreen.modal.secondaryTextColor] - RGBA / RGB / Hex / Color name',
            },
            secondaryTextColor: {
              type: 'string',
            },
            'highlightTextColor:enable': {
              title: '#5.4 Bottom Sheet highlight text color',
              type: 'boolean',
              description:
                '[packagesScreen.modal.highlightTextColor] - RGBA / RGB / Hex / Color name',
            },
            highlightTextColor: {
              type: 'string',
            },
            counter: {
              type: 'object',
              title: '#5.5 Bottom Sheet Quantity Counter',
              properties: {
                'textColor:enable': {
                  title: '#5.5.1 Bottom Sheet Quantity Counter text color',
                  type: 'boolean',
                  description:
                    '[packagesScreen.modal.counter.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
            'resultImageHeight:enable': {
              title: '#5.6 Result image height',
              type: 'boolean',
              description: '[packagesScreen.modal.resultImageHeight]',
            },
            resultImageHeight: {
              type: 'number',
            },
            'resultImageWidth:enable': {
              title: '#5.7 Result image width',
              type: 'boolean',
              description: '[packagesScreen.modal.resultImageWidth]',
            },
            resultImageWidth: {
              type: 'number',
            },
          },
        },
        'dividerColor:enable': {
          title: '#6 Divider Color',
          type: 'boolean',
          description: '[packagesScreen.dividerColor] - RGBA / RGB / Hex / Color name',
        },
        dividerColor: {
          type: 'string',
        },
        success: {
          type: 'object',
          title: '#7 Success state',
          properties: {
            'imageUrl:enable': {
              title: '#7.1 Success state image URL',
              type: 'boolean',
              description: '[packagesScreen.success.imageUrl]',
            },
            imageUrl: {
              type: 'string',
            },
          },
        },
        fail: {
          type: 'object',
          title: '#8 Fail state',
          properties: {
            'imageUrl:enable': {
              title: '#8.1 Fail state image URL',
              type: 'boolean',
              description: '[packagesScreen.fail.imageUrl]',
            },
            imageUrl: {
              type: 'string',
            },
          },
        },
        cart: {
          type: 'object',
          title: '#9 Cart',
          properties: {
            'emptyCartImageUrl:enable': {
              title: '#9.1 Empty Cart image URL',
              type: 'boolean',
              description: '[packagesScreen.cart.emptyCartImageUrl]',
            },
            emptyCartImageUrl: {
              type: 'string',
            },
            'emptyCartImageWidth:enable': {
              title: '#9.2 Empty Cart image width',
              type: 'boolean',
              description: '[packagesScreen.cart.emptyCartImageWidth]',
            },
            emptyCartImageWidth: {
              type: 'number',
            },
            'emptyCartImageHeight:enable': {
              title: '#9.3 Empty Cart image height',
              type: 'boolean',
              description: '[packagesScreen.cart.emptyCartImageHeight]',
            },
            emptyCartImageHeight: {
              type: 'number',
            },
          },
        },
        'cannotRemovePrebookedImageUrl:enable': {
          title: '#10 Cannot Remove Prebooked Popup image URL',
          type: 'boolean',
          description: '[packagesScreen.cannotRemovePrebookedImageUrl]',
        },
        cannotRemovePrebookedImageUrl: {
          type: 'string',
        },
        'hideInactivePackagesPrice:enable': {
          title: '#11 Hide Inactive Packages Price',
          type: 'boolean',
          description: '[packagesScreen.hideInactivePackagesPrice]',
        },
        hideInactivePackagesPrice: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    remoteAssistanceModal: {
      title: '#17 Remote assistance modal',
      type: 'object',
      properties: {
        'hideVideoCallButton:enable': {
          title: '#1 Hide video call button',
          type: 'boolean',
          description: '[remoteAssistanceModal.hideVideoCallButton]',
        },
        hideVideoCallButton: {
          type: undefined,
          enum: [true, false],
        },
        'remoteAssistanceVoiceCallMode:enable': {
          title: '#2 Remote assistance voice call mode',
          type: 'boolean',
          description:
            'Way to make voice call on Remote Assistance [remoteAssistanceModal.remoteAssistanceVoiceCallMode]',
        },
        remoteAssistanceVoiceCallMode: {
          type: 'string',
          enum: ['phoneNumber', 'dashboard'],
        },
        'loginRemoteAssistanceIconUrl:enable': {
          title: '#3 Login remote assistance icon URL',
          type: 'boolean',
          description:
            'Login flow remote assistance icon [remoteAssistanceModal.loginRemoteAssistanceIconUrl]',
        },
        loginRemoteAssistanceIconUrl: {
          type: 'string',
        },
        'homeRemoteAssistanceIconUrl:enable': {
          title: '#4 Home remote assistance icon URL',
          type: 'boolean',
          description:
            'Home flow remote assistance icon (navigation bar) [remoteAssistanceModal.homeRemoteAssistanceIconUrl]',
        },
        homeRemoteAssistanceIconUrl: {
          type: 'string',
        },
        'displayDirectMessageButton:enable': {
          title: '#5 Display direct message button',
          type: 'boolean',
          description: '[remoteAssistanceModal.displayDirectMessageButton]',
        },
        displayDirectMessageButton: {
          type: undefined,
          enum: [true, false],
        },
        'directMessageUrl:enable': {
          title: '#6 Direct message URL',
          type: 'boolean',
          description:
            'URL for deeplink action on pressing direct message button [remoteAssistanceModal.directMessageUrl]',
        },
        directMessageUrl: {
          type: 'string',
        },
        'directMessageFallbackUrl:enable': {
          title: '#7 Direct message fallback URL',
          type: 'boolean',
          description:
            'Fallback URL when directMessageUrl is not valid [remoteAssistanceModal.directMessageFallbackUrl]',
        },
        directMessageFallbackUrl: {
          type: 'string',
        },
        'directMessageIconUrl:enable': {
          title: '#8 Direct message button icon URL',
          type: 'boolean',
          description: 'Direct message button icon [remoteAssistanceModal.directMessageIconUrl]',
        },
        directMessageIconUrl: {
          type: 'string',
        },
        'voiceIconUrl:enable': {
          title: '#9 Voice button icon URL',
          type: 'boolean',
          description: 'Voice button icon [remoteAssistanceModal.voiceIconUrl]',
        },
        voiceIconUrl: {
          type: 'string',
        },
        'videoIconUrl:enable': {
          title: '#10 Video button icon URL',
          type: 'boolean',
          description: 'Voice button icon [remoteAssistanceModal.videoIconUrl]',
        },
        videoIconUrl: {
          type: 'string',
        },
        'backgroundColor:enable': {
          title: '#11 Remote assistance modal background color',
          type: 'boolean',
          description: '[remoteAssistanceModal.backgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backgroundColor: {
          type: 'string',
        },
        'buttonColor:enable': {
          title: '#12 Remote assistance modal button color',
          type: 'boolean',
          description: '[remoteAssistanceModal.buttonColor] - RGBA / RGB / Hex / Color name',
        },
        buttonColor: {
          type: 'string',
        },
        'iconColor:enable': {
          title: "#13 Remote assistance modal button's icon color",
          type: 'boolean',
          description: '[remoteAssistanceModal.iconColor] - RGBA / RGB / Hex / Color name',
        },
        iconColor: {
          type: 'string',
        },
        'textColor:enable': {
          title: '#14 Remote assistance modal text color',
          type: 'boolean',
          description: '[remoteAssistanceModal.textColor] - RGBA / RGB / Hex / Color name',
        },
        textColor: {
          type: 'string',
        },
        'endButtonBackgroundColor:enable': {
          title: '#15 Remote assistance modal call end button background color',
          type: 'boolean',
          description:
            '[remoteAssistanceModal.endButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        endButtonBackgroundColor: {
          type: 'string',
        },
        'audioDisabledButtonColor:enable': {
          title: '#16 Remote assistance mute button active background color',
          type: 'boolean',
          description:
            '[remoteAssistanceModal.audioDisabledButtonColor] - RGBA / RGB / Hex / Color name',
        },
        audioDisabledButtonColor: {
          type: 'string',
        },
        'audioDisabledIconColor:enable': {
          title: '#17 Remote assistance mute button active icon color',
          type: 'boolean',
          description:
            '[remoteAssistanceModal.audioDisabledIconColor] - RGBA / RGB / Hex / Color name',
        },
        audioDisabledIconColor: {
          type: 'string',
        },
        'keypadTextColor:enable': {
          title: '#18 Remote assistance voice call keypad text color',
          type: 'boolean',
          description: '[remoteAssistanceModal.keypadTextColor] - RGBA / RGB / Hex / Color name',
        },
        keypadTextColor: {
          type: 'string',
        },
        'keypadButtonColor:enable': {
          title: '#19 Remote assistance voice call keypad button color',
          type: 'boolean',
          description: '[remoteAssistanceModal.keypadButtonColor] - RGBA / RGB / Hex / Color name',
        },
        keypadButtonColor: {
          type: 'string',
        },
        'keypadBorderColor:enable': {
          title: '#20 Remote assistance voice call keypad button border color',
          type: 'boolean',
          description: '[remoteAssistanceModal.keypadBorderColor] - RGBA / RGB / Hex / Color name',
        },
        keypadBorderColor: {
          type: 'string',
        },
        'disableKeypad:enable': {
          title: '#21 Disable Remote assistance voice call keypad',
          type: 'boolean',
          description: '[remoteAssistanceModal.disableKeypad]',
        },
        disableKeypad: {
          type: undefined,
          enum: [true, false],
        },
        'cameraDisabledButtonColor:enable': {
          title: '#22 Remote assistance camera button active background color',
          type: 'boolean',
          description:
            '[remoteAssistanceModal.cameraDisabledButtonColor] - RGBA / RGB / Hex / Color name',
        },
        cameraDisabledButtonColor: {
          type: 'string',
        },
        'cameraDisabledIconColor:enable': {
          title: '#23 Remote assistance camera button active icon color',
          type: 'boolean',
          description:
            '[remoteAssistanceModal.cameraDisabledIconColor] - RGBA / RGB / Hex / Color name',
        },
        cameraDisabledIconColor: {
          type: 'string',
        },
      },
    },
    notification: {
      title: '#18 Notification modal',
      type: 'object',
      properties: {
        'iconColor:enable': {
          title: '#1 notification icon color(success)',
          type: 'boolean',
          description: '[notification.iconColor]',
        },
        iconColor: {
          type: 'string',
        },
        'buttonColor:enable': {
          title: '#2 button color footer',
          type: 'boolean',
          description: '[notification.buttonColor]',
        },
        buttonColor: {
          type: 'string',
        },
        'buttonTextColor:enable': {
          title: '#3 Button text color',
          type: 'boolean',
          description: '[notification.buttonTextColor]',
        },
        buttonTextColor: {
          type: 'string',
        },
        'successIconImage:enable': {
          title: '#4 Success icon image',
          type: 'boolean',
          description: '[notification.successIconImage]',
        },
        successIconImage: {
          type: 'string',
        },
        'errorIconImage:enable': {
          title: '#5 Error icon image url',
          type: 'boolean',
          description: '[notification.errorIconImage]',
        },
        errorIconImage: {
          type: 'string',
        },
        'outlineButtonColor:enable': {
          title: '#6 Button outline color',
          type: 'boolean',
          description: '[notification.outlineButtonColor]',
        },
        outlineButtonColor: {
          type: 'string',
        },
      },
    },
    shareKeyScreen: {
      title: '#19 Share Key Screens',
      type: 'object',
      properties: {
        'displayShareKeys:enable': {
          title: '#1 Display share key button',
          type: 'boolean',
          description: '[shareKeyScreen.displayShareKeys]',
        },
        displayShareKeys: {
          type: undefined,
          enum: [true, false],
        },
        'headerBackgroundColor:enable': {
          title: '#2 Header background color',
          type: 'boolean',
          description: '[shareKeyScreen.headerBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        headerBackgroundColor: {
          type: 'string',
        },
        'backArrowIconUrl:enable': {
          title: '#3 Back button icon URL',
          type: 'boolean',
          description: '[shareKeyScreen.backArrowIconUrl]',
        },
        backArrowIconUrl: {
          type: 'string',
        },
        'backButtonBackgroundColor:enable': {
          title: '#4 Back button background color',
          type: 'boolean',
          description: '[shareKeyScreen.backButtonBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        backButtonBackgroundColor: {
          type: 'string',
        },
        'tutorialImageUrl:enable': {
          title: '#5 Tutorial image URL',
          type: 'boolean',
          description: '[shareKeyScreen.tutorialImageUrl]',
        },
        tutorialImageUrl: {
          type: 'string',
        },
        guestCard: {
          type: 'object',
          title: '#6 Guest Card',
          properties: {
            'backgroundColor:enable': {
              title: '#6.1 Card background color',
              type: 'boolean',
              description:
                '[shareKeyScreen.guestCard.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'primaryTextColor:enable': {
              title: '#6.2 Card primary text color',
              type: 'boolean',
              description:
                '[shareKeyScreen.guestCard.primaryTextColor] - RGBA / RGB / Hex / Color name',
            },
            primaryTextColor: {
              type: 'string',
            },
            'secondaryTextColor:enable': {
              title: '#6.3 Card secondary text color',
              type: 'boolean',
              description:
                '[shareKeyScreen.guestCard.secondaryTextColor] - RGBA / RGB / Hex / Color name',
            },
            secondaryTextColor: {
              type: 'string',
            },
            'borderColor:enable': {
              title: '#6.4 Card border color',
              type: 'boolean',
              description: '[shareKeyScreen.guestCard.borderColor] - RGBA / RGB / Hex / Color name',
            },
            borderColor: {
              type: 'string',
            },
            'borderWidth:enable': {
              title: '#6.5 Card border thickness',
              type: 'boolean',
              description: '[shareKeyScreen.guestCard.borderWidth]',
            },
            borderWidth: {
              type: 'string',
            },
            'borderRadius:enable': {
              title: '#6.6 Card border radius',
              type: 'boolean',
              description: '[shareKeyScreen.guestCard.borderRadius]',
            },
            borderRadius: {
              type: 'string',
            },
            avatar: {
              type: 'object',
              title: '#6.7 Avatar',
              properties: {
                'backgroundColor:enable': {
                  title: '#6.7.1 Card avatar background color',
                  type: 'boolean',
                  description:
                    '[shareKeyScreen.guestCard.avatar.backgroundColor] - RGBA / RGB / Hex / Color name',
                },
                backgroundColor: {
                  type: 'string',
                },
                'textColor:enable': {
                  title: '#6.7.2 Card avatar text color',
                  type: 'boolean',
                  description:
                    '[shareKeyScreen.guestCard.avatar.textColor] - RGBA / RGB / Hex / Color name',
                },
                textColor: {
                  type: 'string',
                },
              },
            },
          },
        },
        textField: {
          type: 'object',
          title: '#7 Text Field',
          properties: {
            'backgroundColor:enable': {
              title: '#7.1 Text Field background color',
              type: 'boolean',
              description:
                '[shareKeyScreen.textField.backgroundColor] - RGBA / RGB / Hex / Color name',
            },
            backgroundColor: {
              type: 'string',
            },
            'textColor:enable': {
              title: '#7.2 Text Field text color',
              type: 'boolean',
              description: '[shareKeyScreen.textField.textColor] - RGBA / RGB / Hex / Color name',
            },
            textColor: {
              type: 'string',
            },
            'borderColor:enable': {
              title: '#7.3 Text Field border color',
              type: 'boolean',
              description: '[shareKeyScreen.textField.borderColor] - RGBA / RGB / Hex / Color name',
            },
            borderColor: {
              type: 'string',
            },
            'borderWidth:enable': {
              title: '#7.4 Text Field border width',
              type: 'boolean',
              description: '[shareKeyScreen.textField.borderWidth]',
            },
            borderWidth: {
              type: 'string',
            },
            'borderRadius:enable': {
              title: '#7.5 Text Field border radius',
              type: 'boolean',
              description: '[shareKeyScreen.textField.borderRadius]',
            },
            borderRadius: {
              type: 'string',
            },
          },
        },
        successPopup: {
          type: 'object',
          title: '#8 Success Popup',
          properties: {
            'imageUrl:enable': {
              title: '#8.1 Success popup image URL',
              type: 'boolean',
              description: '[shareKeyScreen.successPopup.imageUrl]',
            },
            imageUrl: {
              type: 'string',
            },
          },
        },
        errorPopup: {
          type: 'object',
          title: '#9 Success Popup',
          properties: {
            'imageUrl:enable': {
              title: '#9.1 Error popup image URL',
              type: 'boolean',
              description: '[shareKeyScreen.errorPopup.imageUrl]',
            },
            imageUrl: {
              type: 'string',
            },
          },
        },
      },
    },
    loginScreen: {
      title: '#20 Login screen',
      type: 'object',
      properties: {
        'displayPhoneAuth:enable': {
          title: '#1 Display phone authentication method',
          type: 'boolean',
          description: '[loginScreen.displayPhoneAuth]',
        },
        displayPhoneAuth: {
          type: undefined,
          enum: [true, false],
        },
        'displayEmailAuth:enable': {
          title: '#2 Display email authentication method',
          type: 'boolean',
          description: '[loginScreen.displayEmailAuth]',
        },
        displayEmailAuth: {
          type: undefined,
          enum: [true, false],
        },
        'displayIdVerificationAuth:enable': {
          title: '#3 Display ID verification authentication method',
          type: 'boolean',
          description: '[loginScreen.displayIdVerificationAuth]',
        },
        displayIdVerificationAuth: {
          type: undefined,
          enum: [true, false],
        },
        'displayNameAndDepartureDateVerificationAuth:enable': {
          title: '#4 Display name and departure date authentication method',
          type: 'boolean',
          description: '[loginScreen.displayNameAndDepartureDateVerificationAuth]',
        },
        displayNameAndDepartureDateVerificationAuth: {
          type: undefined,
          enum: [true, false],
        },
        'displayConfirmationCodeVerificationAuth:enable': {
          title: '#5 Display confirmation code authentication method',
          type: 'boolean',
          description: '[loginScreen.displayConfirmationCodeVerificationAuth]',
        },
        displayConfirmationCodeVerificationAuth: {
          type: undefined,
          enum: [true, false],
        },
        'buttonAlignment:enable': {
          title: '#6 Button alignment',
          type: 'boolean',
          description: '[loginScreen.buttonAlignment]',
        },
        buttonAlignment: {
          type: 'string',
          enum: ['left', 'center'],
        },
      },
    },
    userInformationScreen: {
      title: '#21 User information screen',
      type: 'object',
      properties: {
        'disableEditUserInfo:enable': {
          title: '#1 Disable primary user edit user info',
          type: 'boolean',
          description: '[userInformationScreen.disableEditUserInfo]',
        },
        disableEditUserInfo: {
          type: undefined,
          enum: [true, false],
        },
        'cardBackgroundColor:enable': {
          title: '#2 Card background color',
          type: 'boolean',
          description:
            '[userInformationScreen.cardBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        cardBackgroundColor: {
          type: 'string',
        },
        'cardTextColor:enable': {
          title: '#3 Card text color',
          type: 'boolean',
          description: '[userInformationScreen.cardTextColor] - RGBA / RGB / Hex / Color name',
        },
        cardTextColor: {
          type: 'string',
        },
        'obfuscateSensitiveData:enable': {
          title: '#4 Obfuscate email and phone number',
          type: 'boolean',
          description: '[userInformationScreen.obfuscateSensitiveData]',
        },
        obfuscateSensitiveData: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    nameAndDepartureDateVerificationScreen: {
      title: '#22 Name and departure date verification screen',
      type: 'object',
      properties: {
        'dateFormat:enable': {
          title: '#1 Departure date format',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.dateFormat]',
        },
        dateFormat: {
          type: 'string',
        },
      },
    },
    expectedArrivalScreen: {
      type: 'object',
      title: '#23 Expected Arrival Screen',
      properties: {
        'displayExpectedArrival:enable': {
          title: '#1 Display expected arrival screen',
          type: 'boolean',
          description: '[expectedArrivalScreen.displayExpectedArrival]',
        },
        displayExpectedArrival: {
          type: undefined,
          enum: [true, false],
        },
        'confirmImageUrl:enable': {
          title: '#2 Confirmation popup image URL',
          type: 'boolean',
          description: '[expectedArrivalScreen.confirmImageUrl]',
        },
        confirmImageUrl: {
          type: 'string',
        },
        'successImageUrl:enable': {
          title: '#3 Success popup image URL',
          type: 'boolean',
          description: '[expectedArrivalScreen.successImageUrl]',
        },
        successImageUrl: {
          type: 'string',
        },
        'errorImageUrl:enable': {
          title: '#4 Error popup image URL',
          type: 'boolean',
          description: '[expectedArrivalScreen.errorImageUrl]',
        },
        errorImageUrl: {
          type: 'string',
        },
        'datePickerBackgroundColor:enable': {
          title: '#5 Date picker input background color',
          type: 'boolean',
          description: '[expectedArrivalScreen.datePickerBackgroundColor]',
        },
        datePickerBackgroundColor: {
          type: 'string',
        },
        'datePickerTextColor:enable': {
          title: '#6 Date picker input text color',
          type: 'boolean',
          description: '[expectedArrivalScreen.datePickerTextColor]',
        },
        datePickerTextColor: {
          type: 'string',
        },
        'datePickerBorderColor:enable': {
          title: '#7 Date picker input border color',
          type: 'boolean',
          description: '[expectedArrivalScreen.datePickerBorderColor]',
        },
        datePickerBorderColor: {
          type: 'string',
        },
      },
    },
    checkoutScreen: {
      title: '#24 Check-out screen',
      type: 'object',
      properties: {
        'showFolioOnCheckOut:enable': {
          title: '#1 Show folio screen before check-out',
          type: 'boolean',
          description: '[checkoutScreen.showFolioOnCheckOut]',
        },
        showFolioOnCheckOut: {
          type: undefined,
          enum: [true, false],
        },
        'reservationNumberBackgroundColor:enable': {
          title: '#2 Reservation Number Background Color',
          type: 'boolean',
          description:
            '[checkoutScreen.reservationNumberBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        reservationNumberBackgroundColor: {
          type: 'string',
        },
        'reservationNumberTextColor:enable': {
          title: '#3 Reservation Number Text Color',
          type: 'boolean',
          description:
            '[checkoutScreen.reservationNumberTextColor] - RGBA / RGB / Hex / Color name',
        },
        reservationNumberTextColor: {
          type: 'string',
        },
        'dividerColor:enable': {
          title: '#4 Divider Color',
          type: 'boolean',
          description: '[checkoutScreen.dividerColor] - RGBA / RGB / Hex / Color name',
        },
        dividerColor: {
          type: 'string',
        },
        'footerBackgroundColor:enable': {
          title: '#5 Footer Background Color',
          type: 'boolean',
          description: '[checkoutScreen.footerBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        footerBackgroundColor: {
          type: 'string',
        },
        'loadingColor:enable': {
          title: '#6 Loading Color',
          type: 'boolean',
          description: '[checkoutScreen.loadingColor] - RGBA / RGB / Hex / Color name',
        },
        loadingColor: {
          type: 'string',
        },
        'cardBackgroundColor:enable': {
          title: '#7 Card Background Color',
          type: 'boolean',
          description: '[checkoutScreen.cardBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        cardBackgroundColor: {
          type: 'string',
        },
        'infoCardBackgroundColor:enable': {
          title: '#8 Info Card Background Color',
          type: 'boolean',
          description: '[checkoutScreen.infoCardBackgroundColor] - RGBA / RGB / Hex / Color name',
        },
        infoCardBackgroundColor: {
          type: 'string',
        },
        'informationCardEnabled:enable': {
          title: '#9 Information Card Enabled',
          type: 'boolean',
          description: '[checkoutScreen.informationCardEnabled]',
        },
        informationCardEnabled: {
          type: undefined,
          enum: [true, false],
        },
        'successCheckoutImageUrl:enable': {
          title: '#10 Success Checkout Image URL',
          type: 'boolean',
          description: '[checkoutScreen.successCheckoutImageUrl]',
        },
        successCheckoutImageUrl: {
          type: 'string',
        },
        'errorCheckoutImageUrl:enable': {
          title: '#11 Error Checkout Image URL',
          type: 'boolean',
          description: '[checkoutScreen.errorCheckoutImageUrl]',
        },
        errorCheckoutImageUrl: {
          type: 'string',
        },
        'websiteLink:enable': {
          title: '#12 Website Link',
          type: 'boolean',
          description: '[checkoutScreen.websiteLink]',
        },
        websiteLink: {
          type: 'string',
        },
      },
    },
    scanBarcodeScreen: {
      title: '#25 Scan Barcode screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        success: {
          type: 'object',
          title: '#1 Success modal',
          properties: {
            'iconUrl:enable': {
              title: '#1.1 Icon URL',
              type: 'boolean',
              description: '[scanBarcodeScreen.success.iconUrl]',
            },
            iconUrl: {
              type: 'string',
            },
          },
        },
        fail: {
          type: 'object',
          title: '#2 Fail modal',
          properties: {
            'iconUrl:enable': {
              title: '#2.1 Icon URL',
              type: 'boolean',
              description: '[scanBarcodeScreen.fail.iconUrl]',
            },
            iconUrl: {
              type: 'string',
            },
          },
        },
        'buttonBackgroundColor:enable': {
          title: '#3 Button background color',
          type: 'boolean',
          description: '[scanBarcodeScreen.buttonBackgroundColor]',
        },
        buttonBackgroundColor: {
          type: 'string',
        },
        'buttonTextColor:enable': {
          title: '#4 Button text color',
          type: 'boolean',
          description: '[scanBarcodeScreen.buttonTextColor]',
        },
        buttonTextColor: {
          type: 'string',
        },
        instruction: {
          type: 'object',
          title: '#5 Instruction bottom sheet',
          properties: {
            'infoIconUrl:enable': {
              title: '#5.1 Info icon URL',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.infoIconUrl]',
            },
            infoIconUrl: {
              type: 'string',
            },
            'closeIconUrl:enable': {
              title: '#5.2 Close icon URL',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.closeIconUrl]',
            },
            closeIconUrl: {
              type: 'string',
            },
            'imageUrl:enable': {
              title: '#5.3 Instruction image URL',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.imageUrl]',
            },
            imageUrl: {
              type: 'string',
            },
            'imageContainerBackgroundColor:enable': {
              title: '#5.4 Instruction image container background color',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.imageContainerBackgroundColor]',
            },
            imageContainerBackgroundColor: {
              type: 'string',
            },
            'buttonBackgroundColor:enable': {
              title: '#5.5 Instruction button background color',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.buttonBackgroundColor]',
            },
            buttonBackgroundColor: {
              type: 'string',
            },
            'buttonTextColor:enable': {
              title: '#5.6 Instruction button text color',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.buttonTextColor]',
            },
            buttonTextColor: {
              type: 'string',
            },
          },
        },
      },
    },
    scheduleCheckInScreen: {
      type: 'object',
      title: '#26 Schedule check-in screen',
      description: 'This is applicable for mobile web only',
      properties: {
        'confirmImageUrl:enable': {
          title: '#1 Confirmation popup image URL',
          type: 'boolean',
          description: '[scheduleCheckInScreen.confirmImageUrl]',
        },
        confirmImageUrl: {
          type: 'string',
        },
        'successImageUrl:enable': {
          title: '#2 Success popup image URL',
          type: 'boolean',
          description: '[scheduleCheckInScreen.successImageUrl]',
        },
        successImageUrl: {
          type: 'string',
        },
        'errorImageUrl:enable': {
          title: '#3 Error popup image URL',
          type: 'boolean',
          description: '[scheduleCheckInScreen.errorImageUrl]',
        },
        errorImageUrl: {
          type: 'string',
        },
        'datePickerBackgroundColor:enable': {
          title: '#4 Date picker input background color',
          type: 'boolean',
          description: '[scheduleCheckInScreen.datePickerBackgroundColor]',
        },
        datePickerBackgroundColor: {
          type: 'string',
        },
        'datePickerTextColor:enable': {
          title: '#5 Date picker input text color',
          type: 'boolean',
          description: '[scheduleCheckInScreen.datePickerTextColor]',
        },
        datePickerTextColor: {
          type: 'string',
        },
        'datePickerBorderColor:enable': {
          title: '#6 Date picker input border color',
          type: 'boolean',
          description: '[scheduleCheckInScreen.datePickerBorderColor]',
        },
        datePickerBorderColor: {
          type: 'string',
        },
        'timeInterval:enable': {
          title: '#7 Time interval',
          type: 'boolean',
          description: '[scheduleCheckInScreen.timeInterval]',
        },
        timeInterval: {
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30],
        },
        'dateFormat:enable': {
          title: '#8 Date format',
          type: 'boolean',
          description: '[scheduleCheckInScreen.dateFormat]',
        },
        dateFormat: {
          type: 'string',
        },
        'minTime:enable': {
          title: '#9 Minimum check-in time',
          type: 'boolean',
          description: '[scheduleCheckInScreen.minTime] - format must be HH:mm',
        },
        minTime: {
          type: 'string',
        },
        'maxTime:enable': {
          title: '#10 Maximum check-in time',
          type: 'boolean',
          description: '[scheduleCheckInScreen.maxTime] - format must be HH:mm',
        },
        maxTime: {
          type: 'string',
        },
        'modalBackgroundColor:enable': {
          title: '#11 Modal background color',
          type: 'boolean',
          description: '[scheduleCheckInScreen.modalBackgroundColor]',
        },
        modalBackgroundColor: {
          type: 'string',
        },
      },
    },
    simplifiedIdVerification: {
      type: 'object',
      title: '#27 Simplified ID Verification config',
      properties: {
        scanMode: {
          type: 'object',
          title: '#1 Scan mode for each flow',
          properties: {
            'authentication:enable': {
              title: '#1.1 Authentication flow',
              type: 'boolean',
              description: '[simplifiedIdVerification.scanMode.authentication]',
            },
            authentication: {
              type: 'string',
              enum: ['MRZ', 'PDF417'],
            },
            'requirement:enable': {
              title: '#1.2 Requirement flow',
              type: 'boolean',
              description: '[simplifiedIdVerification.scanMode.requirement]',
            },
            requirement: {
              type: 'string',
              enum: ['MRZ', 'PDF417'],
            },
          },
        },
        'selectionCardTextColor:enable': {
          title: '#2 Selection card text color',
          type: 'boolean',
          description: '[simplifiedIdVerification.selectionCardTextColor]',
        },
        selectionCardTextColor: {
          type: 'string',
        },
        'selectionCardButtonColor:enable': {
          title: '#3 Selection card button color',
          type: 'boolean',
          description: '[simplifiedIdVerification.selectionCardButtonColor]',
        },
        selectionCardButtonColor: {
          type: 'string',
        },
        'selectionCardButtonTextColor:enable': {
          title: '#4 Selection card button text color',
          type: 'boolean',
          description: '[simplifiedIdVerification.selectionCardButtonTextColor]',
        },
        selectionCardButtonTextColor: {
          type: 'string',
        },
        'selectionCardColor:enable': {
          title: '#5 Selection card background color',
          type: 'boolean',
          description: '[simplifiedIdVerification.selectionCardColor]',
        },
        selectionCardColor: {
          type: 'string',
        },
      },
    },
    faceLivenessScreen: {
      type: 'object',
      title: '#28 Face liveness screen',
      properties: {
        'instructionImageUrl:enable': {
          title: '#1 Instruction image URL',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionImageUrl]',
        },
        instructionImageUrl: {
          type: 'string',
        },
        'instructionVideoUrl:enable': {
          title: '#2 Instruction video URL',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionVideoUrl]',
        },
        instructionVideoUrl: {
          type: 'string',
        },
        'instructionPanelBgColor:enable': {
          title: '#3 Instruction panel background color',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionPanelBgColor] - Hex',
        },
        instructionPanelBgColor: {
          type: 'string',
        },
        'instructionPanelBorderColor:enable': {
          title: '#4 Instruction panel border color',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionPanelBorderColor] - Hex',
        },
        instructionPanelBorderColor: {
          type: 'string',
        },
        'logoImage:enable': {
          title: '#5 Logo image',
          type: 'boolean',
          description: '[faceLivenessScreen.logoImage]',
        },
        logoImage: {
          type: 'string',
        },
        'homeImage:enable': {
          title: '#6 Home image',
          type: 'boolean',
          description: '[faceLivenessScreen.homeImage]',
        },
        homeImage: {
          type: 'string',
        },
        'settingImage:enable': {
          title: '#7 Setting image',
          type: 'boolean',
          description: '[faceLivenessScreen.settingImage]',
        },
        settingImage: {
          type: 'string',
        },
        'remoteAssistanceImage:enable': {
          title: '#8 Remote assistance image',
          type: 'boolean',
          description: '[faceLivenessScreen.remoteAssistanceImage]',
        },
        remoteAssistanceImage: {
          type: 'string',
        },
        'shouldDisplayBackAlert:enable': {
          title: '#9 Should display back alert',
          type: 'boolean',
          description: '[faceLivenessScreen.shouldDisplayBackAlert]',
        },
        shouldDisplayBackAlert: {
          type: undefined,
          enum: [true, false],
        },
        'instructionDescriptionTextColor:enable': {
          title: '#10 Instruction description text color',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionDescriptionTextColor] - Hex',
        },
        instructionDescriptionTextColor: {
          type: 'string',
        },
        'instructionDescriptionTextFontSize:enable': {
          title: '#11 Instruction description text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionDescriptionTextFontSize]',
        },
        instructionDescriptionTextFontSize: {
          type: 'number',
        },
        'instructionDescriptionTextFontWight:enable': {
          title: '#12 Instruction description text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionDescriptionTextFontWight]',
        },
        instructionDescriptionTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'instructionDescriptionTextAlignment:enable': {
          title: '#13 Instruction description text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionDescriptionTextAlignment]',
        },
        instructionDescriptionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'instructionTextColor:enable': {
          title: '#14 Instruction text color',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionTextColor] - Hex',
        },
        instructionTextColor: {
          type: 'string',
        },
        'instructionTextFontSize:enable': {
          title: '#15 Instruction text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionTextFontSize]',
        },
        instructionTextFontSize: {
          type: 'number',
        },
        'instructionTextFontWight:enable': {
          title: '#16 Instruction text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionTextFontWight]',
        },
        instructionTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'instructionTextAlignment:enable': {
          title: '#17 Instruction text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionTextAlignment]',
        },
        instructionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'headerTextColor:enable': {
          title: '#18 Header text color',
          type: 'boolean',
          description: '[faceLivenessScreen.headerTextColor] - Hex',
        },
        headerTextColor: {
          type: 'string',
        },
        'headerTextFontSize:enable': {
          title: '#19 Header text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.headerTextFontSize]',
        },
        headerTextFontSize: {
          type: 'number',
        },
        'headerTextFontWight:enable': {
          title: '#20 Header text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.headerTextFontWight]',
        },
        headerTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'headerTextAlignment:enable': {
          title: '#21 Header text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.headerTextAlignment]',
        },
        headerTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'subheaderTextColor:enable': {
          title: '#22 Subheader text color',
          type: 'boolean',
          description: '[faceLivenessScreen.subheaderTextColor] - Hex',
        },
        subheaderTextColor: {
          type: 'string',
        },
        'subheaderTextFontSize:enable': {
          title: '#23 Subheader text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.subheaderTextFontSize]',
        },
        subheaderTextFontSize: {
          type: 'number',
        },
        'subheaderTextFontWight:enable': {
          title: '#24 Subheader text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.subheaderTextFontWight]',
        },
        subheaderTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'subheaderTextAlignment:enable': {
          title: '#25 Subheader text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.subheaderTextAlignment]',
        },
        subheaderTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'cameraTextColor:enable': {
          title: '#26 Camera text color',
          type: 'boolean',
          description: '[faceLivenessScreen.cameraTextColor] - Hex',
        },
        cameraTextColor: {
          type: 'string',
        },
        'cameraTextFontSize:enable': {
          title: '#27 Camera text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.cameraTextFontSize]',
        },
        cameraTextFontSize: {
          type: 'number',
        },
        'cameraTextFontWight:enable': {
          title: '#28 Camera text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.cameraTextFontWight]',
        },
        cameraTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'cameraTextAlignment:enable': {
          title: '#29 Camera text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.cameraTextAlignment]',
        },
        cameraTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'backIconUrl:enable': {
          title: '#30 Back icon URL',
          type: 'boolean',
          description: '[faceLivenessScreen.backIconUrl]',
        },
        backIconUrl: {
          type: 'string',
        },
        'backTextColor:enable': {
          title: '#31 Back text color',
          type: 'boolean',
          description: '[faceLivenessScreen.backTextColor] - Hex',
        },
        backTextColor: {
          type: 'string',
        },
        'backTextFontSize:enable': {
          title: '#32 Back text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.backTextFontSize]',
        },
        backTextFontSize: {
          type: 'number',
        },
        'backTextFontWight:enable': {
          title: '#33 Back text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.backTextFontWight]',
        },
        backTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'backTextAlignment:enable': {
          title: '#34 Back text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.backTextAlignment]',
        },
        backTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'startButtonBackgroundColor:enable': {
          title: '#35 Start button background color',
          type: 'boolean',
          description: '[faceLivenessScreen.startButtonBackgroundColor] - Hex',
        },
        startButtonBackgroundColor: {
          type: 'string',
        },
        'startButtonTextColor:enable': {
          title: '#36 Start button text color',
          type: 'boolean',
          description: '[faceLivenessScreen.startButtonTextColor] - Hex',
        },
        startButtonTextColor: {
          type: 'string',
        },
        'faceMatchFailedDescriptionTextColor:enable': {
          title: '#37 Face match failed description text color',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedDescriptionTextColor] - Hex',
        },
        faceMatchFailedDescriptionTextColor: {
          type: 'string',
        },
        'faceMatchFailedDescriptionTextFontSize:enable': {
          title: '#38 Face match failed description text font size',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedDescriptionTextFontSize]',
        },
        faceMatchFailedDescriptionTextFontSize: {
          type: 'number',
        },
        'faceMatchFailedDescriptionTextFontWight:enable': {
          title: '#39 Face match failed description text font weight',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedDescriptionTextFontWight]',
        },
        faceMatchFailedDescriptionTextFontWight: {
          type: undefined,
          enum: ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
        },
        'faceMatchFailedDescriptionTextAlignment:enable': {
          title: '#40 Face match failed description text alignment',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedDescriptionTextAlignment]',
        },
        faceMatchFailedDescriptionTextAlignment: {
          type: undefined,
          enum: ['auto', 'left', 'right', 'center', 'justify'],
        },
        'retryButtonBackgroundColor:enable': {
          title: '#41 Retry button background color',
          type: 'boolean',
          description: '[faceLivenessScreen.retryButtonBackgroundColor] - Hex',
        },
        retryButtonBackgroundColor: {
          type: 'string',
        },
        'retryButtonTextColor:enable': {
          title: '#42 Retry button text color',
          type: 'boolean',
          description: '[faceLivenessScreen.retryButtonTextColor] - Hex',
        },
        retryButtonTextColor: {
          type: 'string',
        },
        'endSessionButtonBackgroundColor:enable': {
          title: '#43 End session button background color',
          type: 'boolean',
          description: '[faceLivenessScreen.endSessionButtonBackgroundColor] - Hex',
        },
        endSessionButtonBackgroundColor: {
          type: 'string',
        },
        'endSessionButtonTextColor:enable': {
          title: '#44 End session button text color',
          type: 'boolean',
          description: '[faceLivenessScreen.endSessionButtonTextColor] - Hex',
        },
        endSessionButtonTextColor: {
          type: 'string',
        },
        'faceMatchFailedImageUrl:enable': {
          title: '#45 Face match failed image URL',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedImageUrl]',
        },
        faceMatchFailedImageUrl: {
          type: 'string',
        },
        'faceLivenessFailedImageUrl:enable': {
          title: '#46 Face liveness failed image URL',
          type: 'boolean',
          description: '[faceLivenessScreen.faceLivenessFailedImageUrl]',
        },
        faceLivenessFailedImageUrl: {
          type: 'string',
        },
      },
    },
    checkinScreen: {
      type: 'object',
      title: '#29 Check-in screen',
      properties: {
        'remoteAssistanceEnabled:enable': {
          title: '#1 Remote Assitance button enabled',
          type: 'boolean',
          description: '[checkinScreen.remoteAssistanceEnabled]',
        },
        remoteAssistanceEnabled: {
          type: undefined,
          enum: [true, false],
        },
        'locationImage:enable': {
          title: '#2 Location image in the screen',
          type: 'boolean',
          description: '[checkinScreen.locationImage]',
        },
        locationImage: {
          type: 'string',
        },
        'locationBrandImage:enable': {
          title: '#3 Location brand image in the screen',
          type: 'boolean',
          description: '[checkinScreen.locationBrandImage]',
        },
        locationBrandImage: {
          type: 'string',
        },
        'locationImageHeight:enable': {
          title: '#4 Location image height',
          type: 'boolean',
          description: '[checkinScreen.locationImageHeight]',
        },
        locationImageHeight: {
          type: 'number',
        },
        'locationImageBorderRadius:enable': {
          title: '#5 Location image border radius',
          type: 'boolean',
          description: '[checkinScreen.locationImageBorderRadius]',
        },
        locationImageBorderRadius: {
          type: 'number',
        },
        'logoImageHeight:enable': {
          title: '#6 Logo image height',
          type: 'boolean',
          description: '[checkinScreen.logoImageHeight]',
        },
        logoImageHeight: {
          type: 'number',
        },
        body: {
          type: 'object',
          title: '#7 Content body style',
          properties: {
            'color:enable': {
              title: '#7.1 Color',
              type: 'boolean',
              description: '[checkinScreen.body.color]',
            },
            color: {
              type: 'string',
            },
            'fontSize:enable': {
              title: '#7.2 Font size',
              type: 'boolean',
              description: '[checkinScreen.body.fontSize]',
            },
            fontSize: {
              type: 'number',
            },
            'variant:enable': {
              title: '#7.3 Text variant',
              type: 'boolean',
              description: '[checkinScreen.body.variant]',
            },
            variant: {
              type: 'string',
            },
          },
        },
        'phoneNumberDisabled:enable': {
          title: '#8 Phone number check-in button disabled',
          type: 'boolean',
          description: '[checkinScreen.phoneNumberDisabled]',
        },
        phoneNumberDisabled: {
          type: undefined,
          enum: [true, false],
        },
        'confirmationNumberDisabled:enable': {
          title: '#9 Confirmation number check-in button disabled',
          type: 'boolean',
          description: '[checkinScreen.confirmationNumberDisabled]',
        },
        confirmationNumberDisabled: {
          type: undefined,
          enum: [true, false],
        },
        'confirmationAndLastNameDisabled:enable': {
          title: '#10 Confirmation and last name check-in button disabled',
          type: 'boolean',
          description: '[checkinScreen.confirmationAndLastNameDisabled]',
        },
        confirmationAndLastNameDisabled: {
          type: undefined,
          enum: [true, false],
        },
        'nameAndDepartureDateDisabled:enable': {
          title: '#11 Confirmation and departure date check-in button disabled',
          type: 'boolean',
          description: '[checkinScreen.nameAndDepartureDateDisabled]',
        },
        nameAndDepartureDateDisabled: {
          type: undefined,
          enum: [true, false],
        },
        'emailDisabled:enable': {
          title: '#12 Email authentication check-in button disabled',
          type: 'boolean',
          description: '[checkinScreen.emailDisabled]',
        },
        emailDisabled: {
          type: undefined,
          enum: [true, false],
        },
      },
    },
    authentication: {
      type: 'object',
      title: '#30 Authentication screen',
      properties: {
        'backgroundColor:enable': {
          title: '#1 Screen background color',
          type: 'boolean',
          description: '[authentication.backgroundColor]',
        },
        backgroundColor: {
          type: 'string',
        },
        'titleTextColor:enable': {
          title: '#2 Screen title text color',
          type: 'boolean',
          description: '[authentication.titleTextColor]',
        },
        titleTextColor: {
          type: 'string',
        },
        'descriptionTextColor:enable': {
          title: '#3 Screen description text color',
          type: 'boolean',
          description: '[authentication.descriptionTextColor]',
        },
        descriptionTextColor: {
          type: 'string',
        },
        'headerIconBackgroundColor:enable': {
          title: '#4 Screen header icon background color',
          type: 'boolean',
          description: '[authentication.headerIconBackgroundColor]',
        },
        headerIconBackgroundColor: {
          type: 'string',
        },
        'textFieldPlaceholderTextColor:enable': {
          title: '#5 Screen text field placeholder text color',
          type: 'boolean',
          description: '[authentication.textFieldPlaceholderTextColor]',
        },
        textFieldPlaceholderTextColor: {
          type: 'string',
        },
        'textFieldTextColor:enable': {
          title: '#6 Screen text field text color',
          type: 'boolean',
          description: '[authentication.textFieldTextColor]',
        },
        textFieldTextColor: {
          type: 'string',
        },
        'headerIconColor:enable': {
          title: '#7 Screen header icon color',
          type: 'boolean',
          description: '[authentication.headerIconColor]',
        },
        headerIconColor: {
          type: 'string',
        },
        'dividerColor:enable': {
          title: '#8 Screen divider color',
          type: 'boolean',
          description: '[authentication.dividerColor]',
        },
        dividerColor: {
          type: 'string',
        },
        'tenantLogo:enable': {
          title: '#9 Screen tenant logo image',
          type: 'boolean',
          description: '[authentication.tenantLogo]',
        },
        tenantLogo: {
          type: 'string',
        },
        submitButton: {
          type: 'object',
          title: '#10 Submit button',
          properties: {
            'textColor:enable': {
              title: '#10.1 Text color',
              type: 'boolean',
              description: '[authentication.textColor]',
            },
            textColor: {
              type: 'string',
            },
            'backgroundColor:enable': {
              title: '#10.2 Text color',
              type: 'boolean',
              description: '[authentication.backgroundColor]',
            },
            backgroundColor: {
              type: 'string',
            },
            'borderColor:enable': {
              title: '#10.3 Border color',
              type: 'boolean',
              description: '[authentication.borderColor]',
            },
            borderColor: {
              type: 'string',
            },
            'borderWidth:enable': {
              title: '#10.4 Border color',
              type: 'boolean',
              description: '[authentication.borderWidth]',
            },
            borderWidth: {
              type: 'number',
            },
            'borderRadius:enable': {
              title: '#10.5 Border radius',
              type: 'boolean',
              description: '[authentication.borderRadius]',
            },
            borderRadius: {
              type: 'number',
            },
          },
        },
        authMethods: {
          type: 'object',
          title: '#11 Authentication methods',
          properties: {
            phoneNumber: {
              type: 'object',
              title: '#11.1 Phone number',
              properties: {
                'title:enable': {
                  title: '#11.1.1 Phone number authentication screen title',
                  type: 'boolean',
                  description: '[authMethods.phoneNumber.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#11.1.2 Phone number authentication screen description',
                  type: 'boolean',
                  description: '[authMethods.phoneNumber.description]',
                },
                description: {
                  type: 'string',
                },
                'submitButton:enable': {
                  title: '#11.1.3 Phone number authentication screen submit button title',
                  type: 'boolean',
                  description: '[authMethods.phoneNumber.submitButton]',
                },
                submitButton: {
                  type: 'string',
                },
                'errorMessage:enable': {
                  title: '#11.1.4 Phone number authentication screen error message',
                  type: 'boolean',
                  description: '[authMethods.phoneNumber.errorMessage]',
                },
                errorMessage: {
                  type: 'string',
                },
                placeholder: {
                  type: 'object',
                  title: '#11.1.5 Placeholder',
                  properties: {
                    'phoneNumber:enable': {
                      title: '#11.1.5.1 Phone number input placeholder',
                      type: 'boolean',
                      description: '[authMethods.phoneNumber.placeholder.phoneNumber]',
                    },
                    phoneNumber: {
                      type: 'string',
                    },
                  },
                },
              },
            },
            email: {
              type: 'object',
              title: '#11.2 Email',
              properties: {
                'title:enable': {
                  title: '#11.2.1 Email number authentication screen title',
                  type: 'boolean',
                  description: '[authMethods.email.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#11.2.2 Email authentication screen description',
                  type: 'boolean',
                  description: '[authMethods.email.description]',
                },
                description: {
                  type: 'string',
                },
                'submitButton:enable': {
                  title: '#11.2.3 Email authentication screen submit button title',
                  type: 'boolean',
                  description: '[authMethods.email.submitButton]',
                },
                submitButton: {
                  type: 'string',
                },
                'errorMessage:enable': {
                  title: '#11.2.4 Email authentication screen error message',
                  type: 'boolean',
                  description: '[authMethods.email.errorMessage]',
                },
                errorMessage: {
                  type: 'string',
                },
                placeholder: {
                  type: 'object',
                  title: '#11.2.5 Placeholder',
                  properties: {
                    'email:enable': {
                      title: '#11.2.5.1 Email input placeholder',
                      type: 'boolean',
                      description: '[authMethods.email.placeholder.email]',
                    },
                    email: {
                      type: 'string',
                    },
                  },
                },
              },
            },
            oneTimePassword: {
              type: 'object',
              title: '#11.3 One time password',
              properties: {
                phoneNumber: {
                  type: 'object',
                  title: '#11.3.1 Phone number validation code screen',
                  properties: {
                    'title:enable': {
                      title: '#11.3.1.1 Phone number authentication screen title',
                      type: 'boolean',
                      description: '[authMethods.oneTimePassword.phoneNumber.title]',
                    },
                    title: {
                      type: 'string',
                    },
                    'description:enable': {
                      title: '#11.3.1.2 Phone number authentication screen description',
                      type: 'boolean',
                      description: '[authMethods.oneTimePassword.phoneNumber.description]',
                    },
                    description: {
                      type: 'string',
                    },
                    'submitButton:enable': {
                      title: '#11.3.1.3 Phone number authentication screen submit button title',
                      type: 'boolean',
                      description: '[authMethods.oneTimePassword.phoneNumber.submitButton]',
                    },
                    submitButton: {
                      type: 'string',
                    },
                  },
                },
                email: {
                  type: 'object',
                  title: '#11.3.2 Email validation code screen',
                  properties: {
                    'title:enable': {
                      title: '#11.3.2.1 Email authentication screen title',
                      type: 'boolean',
                      description: '[authMethods.oneTimePassword.email.title]',
                    },
                    title: {
                      type: 'string',
                    },
                    'description:enable': {
                      title: '#11.3.2.2 Email authentication screen description',
                      type: 'boolean',
                      description: '[authMethods.oneTimePassword.email.description]',
                    },
                    description: {
                      type: 'string',
                    },
                    'submitButton:enable': {
                      title: '#11.3.2.3 Email authentication screen submit button title',
                      type: 'boolean',
                      description: '[authMethods.oneTimePassword.email.submitButton]',
                    },
                    submitButton: {
                      type: 'string',
                    },
                  },
                },
              },
            },
            nameAndDepartureDate: {
              type: 'object',
              title: '#11.4 Name and departure date',
              properties: {
                'title:enable': {
                  title: '#11.4.1 Phone number authentication screen title',
                  type: 'boolean',
                  description: '[authMethods.nameAndDepartureDate.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#11.4.2 Phone number authentication screen description',
                  type: 'boolean',
                  description: '[authMethods.nameAndDepartureDate.description]',
                },
                description: {
                  type: 'string',
                },
                'submitButton:enable': {
                  title: '#11.4.3 Phone number authentication screen submit button title',
                  type: 'boolean',
                  description: '[authMethods.nameAndDepartureDate.submitButton]',
                },
                submitButton: {
                  type: 'string',
                },
                'errorMessage:enable': {
                  title: '#11.4.4 Phone number authentication screen error message',
                  type: 'boolean',
                  description: '[authMethods.nameAndDepartureDate.errorMessage]',
                },
                errorMessage: {
                  type: 'string',
                },
                placeholder: {
                  type: 'object',
                  title: '#11.4.5 Placeholder',
                  properties: {
                    'lastName:enable': {
                      title: '#11.4.5.1 Last name input placeholder',
                      type: 'boolean',
                      description: '[authMethods.nameAndDepartureDate.placeholder.lastName]',
                    },
                    lastName: {
                      type: 'string',
                    },
                    'departureDate:enable': {
                      title: '#11.4.5.2 Departure date input placeholder',
                      type: 'boolean',
                      description: '[authMethods.nameAndDepartureDate.placeholder.departureDate]',
                    },
                    departureDate: {
                      type: 'string',
                    },
                  },
                },
              },
            },
            confirmationCode: {
              type: 'object',
              title: '#11.5 Confirmation code',
              properties: {
                'title:enable': {
                  title: '#11.5.1 Confirmation code authentication screen title',
                  type: 'boolean',
                  description: '[authMethods.confirmationCode.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#11.5.2 Confirmation code authentication screen description',
                  type: 'boolean',
                  description: '[authMethods.confirmationCode.description]',
                },
                description: {
                  type: 'string',
                },
                'submitButton:enable': {
                  title: '#11.5.3 Confirmation code authentication screen submit button title',
                  type: 'boolean',
                  description: '[authMethods.confirmationCode.submitButton]',
                },
                submitButton: {
                  type: 'string',
                },
                'errorMessage:enable': {
                  title: '#11.5.4 Confirmation code authentication screen error message',
                  type: 'boolean',
                  description: '[authMethods.confirmationCode.errorMessage]',
                },
                errorMessage: {
                  type: 'string',
                },
                placeholder: {
                  type: 'object',
                  title: '#11.5.5 Placeholder',
                  properties: {
                    'location:enable': {
                      title: '#11.5.5.1 Location input placeholder',
                      type: 'boolean',
                      description: '[authMethods.confirmationCode.placeholder.location]',
                    },
                    location: {
                      type: 'string',
                    },
                    'confirmationCode:enable': {
                      title: '#11.5.5.2 Confirmation code input placeholder',
                      type: 'boolean',
                      description: '[authMethods.confirmationCode.placeholder.confirmationCode]',
                    },
                    confirmationCode: {
                      type: 'string',
                    },
                  },
                },
              },
            },
            confirmationCodeAndLastName: {
              type: 'object',
              title: '11.6 Confirmation code and last name',
              properties: {
                'title:enable': {
                  title: '#11.6.1 Confirmation code and last name authentication screen title',
                  type: 'boolean',
                  description: '[authMethods.confirmationCodeAndLastName.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title:
                    '#11.6.2 Confirmation code and last name authentication screen description',
                  type: 'boolean',
                  description: '[authMethods.confirmationCodeAndLastName.description]',
                },
                description: {
                  type: 'string',
                },
                'submitButton:enable': {
                  title:
                    '#11.6.3 Confirmation code and last name authentication screen submit button title',
                  type: 'boolean',
                  description: '[authMethods.confirmationCodeAndLastName.submitButton]',
                },
                submitButton: {
                  type: 'string',
                },
                'errorMessage:enable': {
                  title:
                    '#11.6.4 Confirmation code and last name authentication screen error message',
                  type: 'boolean',
                  description: '[authMethods.confirmationCodeAndLastName.errorMessage]',
                },
                errorMessage: {
                  type: 'string',
                },
                placeholder: {
                  type: 'object',
                  title: '#11.6.5 Placeholder',
                  properties: {
                    'lastName:enable': {
                      title: '#11.6.5.1 Last name input placeholder',
                      type: 'boolean',
                      description: '[authMethods.confirmationCodeAndLastName.placeholder.lastName]',
                    },
                    lastName: {
                      type: 'string',
                    },
                    'confirmationCode:enable': {
                      title: '#11.6.5.2 Confirmation code input placeholder',
                      type: 'boolean',
                      description:
                        '[authMethods.confirmationCodeAndLastName.placeholder.confirmationCode]',
                    },
                    confirmationCode: {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },
        card: {
          type: 'object',
          title: '#12 Card',
          properties: {
            error: {
              type: 'object',
              title: '#13.1 Error settings',
              properties: {
                'backgroundColor:enable': {
                  title: '#13.1.1 Card background color',
                  type: 'boolean',
                  description: '[card.error.backgroundColor]',
                },
                backgroundColor: {
                  type: 'string',
                },
                'borderRadius:enable': {
                  title: '#13.1.2 Border radius',
                  type: 'boolean',
                  description: '[card.error.borderRadius]',
                },
                borderRadius: {
                  type: 'number',
                },
                'padding:enable': {
                  title: '#13.1.3 Padding',
                  type: 'boolean',
                  description: '[card.error.padding]',
                },
                padding: {
                  type: 'number',
                },
              },
            },
          },
        },
      },
    },
    stayManagementScreen: {
      type: 'object',
      title: '#31 Stay management screen',
      properties: {
        'remoteAssistanceEnabled:enable': {
          title: '#1 Remote Assitance button enabled',
          type: 'boolean',
          description: '[stayManagementScreen.remoteAssistanceEnabled]',
        },
        remoteAssistanceEnabled: {
          type: undefined,
          enum: [true, false],
        },
        'locationImage:enable': {
          title: '#2 Location image in the screen',
          type: 'boolean',
          description: '[stayManagementScreen.locationImage]',
        },
        locationImage: {
          type: 'string',
        },
        'locationBrandImage:enable': {
          title: '#3 Location brand image in the screen',
          type: 'boolean',
          description: '[stayManagementScreen.locationBrandImage]',
        },
        locationBrandImage: {
          type: 'string',
        },
        'locationImageHeight:enable': {
          title: '#4 Location image height',
          type: 'boolean',
          description: '[stayManagementScreen.locationImageHeight]',
        },
        locationImageHeight: {
          type: 'number',
        },
        'locationImageBorderRadius:enable': {
          title: '#5 Location image border radius',
          type: 'boolean',
          description: '[stayManagementScreen.locationImageBorderRadius]',
        },
        locationImageBorderRadius: {
          type: 'number',
        },
        'logoImageHeight:enable': {
          title: '#6 Logo image height',
          type: 'boolean',
          description: '[stayManagementScreen.logoImageHeight]',
        },
        logoImageHeight: {
          type: 'number',
        },
        body: {
          type: 'object',
          title: '#7 Content body style',
          properties: {
            'color:enable': {
              title: '#7.1 Color',
              type: 'boolean',
              description: '[stayManagementScreen.body.color]',
            },
            color: {
              type: 'string',
            },
            'fontSize:enable': {
              title: '#7.2 Font size',
              type: 'boolean',
              description: '[stayManagementScreen.body.fontSize]',
            },
            fontSize: {
              type: 'number',
            },
            'variant:enable': {
              title: '#7.3 Text variant',
              type: 'boolean',
              description: '[stayManagementScreen.body.variant]',
            },
            variant: {
              type: 'string',
            },
          },
        },
        'resizeMode:enable': {
          title: '#8 Resize mode for the main image',
          type: 'boolean',
          description: '[stayManagementScreen.resizeMode]',
        },
        resizeMode: {
          type: undefined,
          enum: ['cover', 'contain', 'stretch', 'repeat', 'center'],
        },
        'logoResizeMode:enable': {
          title: '#9 Resize mode for the logo image',
          type: 'boolean',
          description: '[stayManagementScreen.logoResizeMode]',
        },
        logoResizeMode: {
          type: undefined,
          enum: ['cover', 'contain', 'stretch', 'repeat', 'center'],
        },
      },
    },
    selectReservationScreen: {
      type: 'object',
      title: '#32 Select Reservation screen',
      properties: {
        item: {
          type: 'object',
          title: '#1 Select Reservation list item',
          properties: {
            'iconContainerColor:enable': {
              title: '#1.1 Icon container color',
              type: 'boolean',
              description: '[selectReservationScreen.item.iconCantainerColor]',
            },
            iconContainerColor: {
              type: 'string',
            },
            'titleColor:enable': {
              title: '#1.2 Item title color',
              type: 'boolean',
              description: '[selectReservationScreen.item.titleColor]',
            },
            titleColor: {
              type: 'string',
            },
            'bodyColor:enable': {
              title: '#1.3 Item content body color',
              type: 'boolean',
              description: '[selectReservationScreen.item.bodyColor]',
            },
            bodyColor: {
              type: 'string',
            },
            'icon:enable': {
              title: '#1.4 Icon name',
              type: 'boolean',
              description: '[selectReservationScreen.item.icon]',
            },
            icon: {
              type: 'string',
            },
            'backgroundColor:enable': {
              title: '#1.5 Item background color',
              type: 'boolean',
              description: '[selectReservationScreen.item.backgroundColor]',
            },
            backgroundColor: {
              type: 'string',
            },
          },
        },
      },
    },
  },
};

export const mobileThemeCustomizationUiSchema = {
  general: {
    'ui:title': '‎',
    palette: {
      primary: {
        main: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        contrastText: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      text: {
        main: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      background: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    actionButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    actionButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    actionButtonBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    actionButtonBorderWidth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    actionButtonShadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    loadingSpinnerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    requirementStatus: {
      inprogress: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      completed: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        completedIconUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
      todo: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
    backIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    button: {
      disabled: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
    modal: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    errorIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    successIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    disabledCheckInTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authenticationEnabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hideDownloadAppButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    disableCheckInButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerLogoImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    headerLogoHeight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerLogoWidth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enableOnDesktop: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    borderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    shadowColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textInputColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    primaryTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    secondaryTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    disabledButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    logoImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    customBackIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    authActionButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authActionButtonBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authActionButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authTextInputBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authTextInputBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authTextInputColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authButtonBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    authBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    defaultPhonePrefix: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enableGetQrCodeButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayWalletPassButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    downloadAppButtonUrl: {
      'ui:label': false,
      'ui:readonly': true,
    },
    downloadiOSAppButtonUrl: {
      'ui:label': false,
      'ui:readonly': true,
    },
    downloadAndroidAppButtonUrl: {
      'ui:label': false,
      'ui:readonly': true,
    },
    disableRemoteAssistance: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    appGooglePlayUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    appAppStoreUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    defaultCountryCode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    inputText: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      inactiveBorderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      activeBorderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    actionList: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      fontSize: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      elevation: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  notification: {
    'ui:title': '‎',
    iconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    successIconImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    errorIconImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    buttonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    outlineButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    checkOutIconBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  dashboardScreen: {
    'ui:title': '‎',
    displayCheckInButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayCheckoutButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    keycardUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    logoUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    displayYourStayTab: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayServicesTab: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayStaySection: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayAmenitiesSection: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayKeycardSection: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayQrSection: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayInfoSection: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayRoomSection: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayFoodSection: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    containerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectReservationBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    checkInIconImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    checkOutIconImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    checkInIconBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    checkOutIconBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    reservationCardFallbackImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    inContainerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    inContainerIconTintColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectReservationTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    checkInButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    checkInButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    checkOutButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    checkOutButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    shouldHideKeycardBeforeCheckIn: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    additionalInfoRequired: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    keyCardGradientStartColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    keyCardGradientEndColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    feedbackBoxBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    feedbackTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    stayTabDateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
    accessGrantSelectionDateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
    keycardDateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
    displayRoomNumberOnKeycard: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    keycardRoomNumberTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    displayKeycardHeaderText: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    keycardHeaderTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    requirementOverlayTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    requirementOverlayIconBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    requirementOverlayButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    requirementOverlayButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    requirementOverlayIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    inContainerDividerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    keycardHeaderTextSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    keycardRoomNumberTextSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    keycardErrorImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    servicePriceTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    shouldCreateKeysOnlyWhenNoValidCredentialsExist: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  profileScreen: {
    'ui:title': '‎',
    feedbackEmailUrl: {
      'ui:label': false,
      'ui:readonly': true,
    },
    feedbackFallbackUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    hidePaymentSection: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    faqUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    privacyUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    tosUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    displayFaq: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  captureSecurityDepositMethodScreen: {
    'ui:title': '‎',
    shouldDisplayCreditCardButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  currentBookingScreen: {
    'ui:title': '‎',
    cardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    requirementButton: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      shadowColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      leftIcon: {
        fillColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      rightIcon: {
        completed: {
          fillColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          strokeColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          customIconUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
        },
        incompleted: {
          fillColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          customIconUrl: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:widget': 'uri',
            'ui:fieldtype': 'link',
          },
        },
      },
      termsAndConditionIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      paymentAuthorizationIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      paymentStatusIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      idVerificationIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      paymentCaptureIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    incompleteRequirements: {
      iconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    fastPass: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      successTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      successIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    cardDividerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardShadow: {
      shadowColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      shadowOffset: {
        width: {
          'ui:label': false,
          'ui:readonly': true,
        },
        height: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      shadowOpacity: {
        'ui:label': false,
        'ui:readonly': true,
      },
      shadowRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      elevation: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    dateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardTitleColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardSubTitleColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    hideDownloadAppButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  idVerificationInitScreen: {
    'ui:title': '‎',
    selectCountry: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      changeButtonBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      changeButtonTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    passportIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    idCardIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    driverLicenseIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    consent: {
      closeIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      privacyPolicyUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      buttonBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      buttonTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
  },
  idVerificationScanDocumentScreen: {
    'ui:title': '‎',
    switchCameraButton: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    idCard: {
      front: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
      back: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
      face: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
    },
    passport: {
      front: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
      face: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
    },
    driverLicense: {
      front: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
      back: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
      face: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
    },
    enableFaceLiveness: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  idVerificationResultScreen: {
    'ui:title': '‎',
    idVerificationSuccess: {
      iconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
  },
  paymentStatusScreen: {
    'ui:title': '‎',
    visaIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    mastercardIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    amexIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    applepayIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    inputText: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      hintTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      inputTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    success: {
      iconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    fail: {
      iconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    hideVisaIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hideMastercardIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hideAmexIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    hideApplepayIcon: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cardIconUrlList: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        'ui:label': false,
      },
    },
  },
  paymentAuthorizationSuccessScreen: {
    iconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
  },
  paymentAuthorizationErrorScreen: {
    iconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
  },
  termsAndConditionsScreen: {
    signatureBox: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      hintTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      penColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    clearButton: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    success: {
      iconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    fail: {
      iconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
  },
  successScreen: {
    successIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    closeIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    hideQRCode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    bottomStickyBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    titleColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    walletIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    walletIconPosition: {
      'ui:label': false,
      'ui:readonly': true,
    },
    hideDownloadAppButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  userDetailsScreen: {
    resources: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      card: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        primaryTextColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        secondaryTextColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
    header: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
    roomInfoVisible: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    initialBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    disableResourceRate: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    disableUsersEditButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  roomNotReadyScreen: {
    closeIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    iconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
  },
  packagesScreen: {
    categoryButton: {
      selected: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
      unselected: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
    card: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      primaryTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      highlightTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      addButton: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        quantity: {
          backgroundColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
          textColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
        },
      },
      primaryButton: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        buttonType: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      secondaryButton: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        buttonType: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      modal: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        primaryTextColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        secondaryTextColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        highlightTextColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        counter: {
          textColor: {
            'ui:label': false,
            'ui:readonly': true,
            'ui:fieldtype': 'color',
          },
        },
        resultImageHeight: {
          'ui:label': false,
          'ui:readonly': true,
        },
        resultImageWidth: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      dividerColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      success: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
      fail: {
        imageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
      },
      cart: {
        emptyCartImageUrl: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:widget': 'uri',
          'ui:fieldtype': 'link',
        },
        emptyCartImageWidth: {
          'ui:label': false,
          'ui:readonly': true,
        },
        emptyCartImageHeight: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      cannotRemovePrebookedImageUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      hideInactivePackagesPrice: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'select',
      },
    },
  },
  remoteAssistanceModal: {
    hideVideoCallButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    remoteAssistanceVoiceCallMode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    loginRemoteAssistanceIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    homeRemoteAssistanceIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    displayDirectMessageButton: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    directMessageUrl: {
      'ui:label': false,
      'ui:readonly': true,
    },
    directMessageFallbackUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    directMessageIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    voiceIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    videoIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    iconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    endButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    audioDisabledButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    audioDisabledIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    keypadTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    keypadButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    keypadBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    disableKeypad: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cameraDisabledButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cameraDisabledIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  shareKeyScreen: {
    displayShareKeys: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backArrowIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    backButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    tutorialImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    guestCard: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      primaryTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      secondaryTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
      avatar: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        textColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
      },
    },
    textField: {
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    successPopup: {
      imageUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    errorPopup: {
      imageUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
  },
  loginScreen: {
    displayPhoneAuth: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayEmailAuth: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayIdVerificationAuth: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayNameAndDepartureDateVerificationAuth: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    displayConfirmationCodeVerificationAuth: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    buttonAlignment: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  userInformationScreen: {
    disableEditUserInfo: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    obfuscateSensitiveData: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  nameAndDepartureDateVerificationScreen: {
    dateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  expectedArrivalScreen: {
    displayExpectedArrival: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    confirmImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    successImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    errorImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    datePickerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    datePickerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    datePickerBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  checkoutScreen: {
    showFolioOnCheckOut: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    reservationNumberBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'color',
    },
    reservationNumberTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'color',
    },
    dividerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'color',
    },
    footerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'color',
    },
    loadingColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'color',
    },
    cardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'color',
    },
    infoCardBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'color',
    },
    informationCardEnabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    successCheckoutImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    errorCheckoutImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    websiteLink: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
  },
  scanBarcodeScreen: {
    success: {
      iconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    fail: {
      iconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
    },
    buttonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    buttonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instruction: {
      infoIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      closeIconUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      imageUrl: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:widget': 'uri',
        'ui:fieldtype': 'link',
      },
      imageContainerBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      buttonBackgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      buttonTextColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
  },
  scheduleCheckInScreen: {
    confirmImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    successImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    errorImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    datePickerBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    datePickerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    datePickerBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    timeInterval: {
      'ui:label': false,
      'ui:readonly': true,
    },
    dateFormat: {
      'ui:label': false,
      'ui:readonly': true,
    },
    minTime: {
      'ui:label': false,
      'ui:readonly': true,
    },
    maxTime: {
      'ui:label': false,
      'ui:readonly': true,
    },
    modalBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  simplifiedIdVerification: {
    'ui:title': '‎',
    scanMode: {
      authentication: {
        'ui:label': false,
        'ui:readonly': true,
      },
      requirement: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    selectionCardButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectionCardButtonColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectionCardTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    selectionCardColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
  },
  faceLivenessScreen: {
    'ui:title': '‎',
    instructionImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    instructionVideoUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    instructionPanelBgColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionPanelBorderColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    logoImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    homeImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    settingImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    remoteAssistanceImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    shouldDisplayBackAlert: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionDescriptionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionDescriptionTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionDescriptionTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionDescriptionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    instructionTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    instructionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    headerTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    subheaderTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    subheaderTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subheaderTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    subheaderTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cameraTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    cameraTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    cameraTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    backIconUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    backTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    backTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    backTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    startButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    startButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    faceMatchFailedDescriptionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    faceMatchFailedDescriptionTextFontSize: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceMatchFailedDescriptionTextFontWight: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    faceMatchFailedDescriptionTextAlignment: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    retryButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    retryButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    endSessionButtonBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    endSessionButtonTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    faceMatchFailedImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    faceLivenessFailedImageUrl: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
  },
  checkinScreen: {
    'ui:title': '‎',
    remoteAssistanceEnabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    locationImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    locationBrandImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    locationImageHeight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    locationImageBorderRadius: {
      'ui:label': false,
      'ui:readonly': true,
    },
    logoImageHeight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    body: {
      color: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      fontSize: {
        'ui:label': false,
        'ui:readonly': true,
      },
      variant: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    phoneNumberDisabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    confirmationNumberDisabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    confirmationAndLastNameDisabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    nameAndDepartureDateDisabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    emailDisabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  stayManagementScreen: {
    'ui:title': '‎',
    remoteAssistanceEnabled: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    locationImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    locationBrandImage: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    locationImageHeight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    locationImageBorderRadius: {
      'ui:label': false,
      'ui:readonly': true,
    },
    logoImageHeight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    body: {
      color: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      fontSize: {
        'ui:label': false,
        'ui:readonly': true,
      },
      variant: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    resizeMode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
    logoResizeMode: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'select',
    },
  },
  authentication: {
    'ui:title': '‎',
    backgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    titleTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    descriptionTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerIconBackgroundColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textFieldPlaceholderTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    textFieldTextColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    headerIconColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    dividerColor: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:fieldtype': 'color',
    },
    tenantLogo: {
      'ui:label': false,
      'ui:readonly': true,
      'ui:widget': 'uri',
      'ui:fieldtype': 'link',
    },
    submitButton: {
      textColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      borderWidth: {
        'ui:label': false,
        'ui:readonly': true,
      },
      borderRadius: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    authMethods: {
      phoneNumber: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        submitButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
        errorMessage: {
          'ui:label': false,
          'ui:readonly': true,
        },
        placeholder: {
          phoneNumber: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
      },
      email: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        submitButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
        errorMessage: {
          'ui:label': false,
          'ui:readonly': true,
        },
        placeholder: {
          email: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
      },
      oneTimePassword: {
        phoneNumber: {
          title: {
            'ui:label': false,
            'ui:readonly': true,
          },
          description: {
            'ui:label': false,
            'ui:readonly': true,
          },
          submitButton: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        email: {
          title: {
            'ui:label': false,
            'ui:readonly': true,
          },
          description: {
            'ui:label': false,
            'ui:readonly': true,
          },
          submitButton: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
      },
      nameAndDepartureDate: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        submitButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
        errorMessage: {
          'ui:label': false,
          'ui:readonly': true,
        },
        placeholder: {
          lastName: {
            'ui:label': false,
            'ui:readonly': true,
          },
          departureDate: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
      },
      confirmationCode: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        submitButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
        errorMessage: {
          'ui:label': false,
          'ui:readonly': true,
        },
        placeholder: {
          location: {
            'ui:label': false,
            'ui:readonly': true,
          },
          confirmationCode: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
      },
      confirmationCodeAndLastName: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        submitButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
        errorMessage: {
          'ui:label': false,
          'ui:readonly': true,
        },
        placeholder: {
          lastName: {
            'ui:label': false,
            'ui:readonly': true,
          },
          confirmationCode: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
      },
    },
    card: {
      error: {
        backgroundColor: {
          'ui:label': false,
          'ui:readonly': true,
          'ui:fieldtype': 'color',
        },
        borderRadius: {
          'ui:label': false,
          'ui:readonly': true,
        },
        padding: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
  },
  selectReservationScreen: {
    'ui:title': '‎',
    item: {
      iconContainerColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      titleColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      bodyColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
      icon: {
        'ui:label': false,
        'ui:readonly': true,
      },
      backgroundColor: {
        'ui:label': false,
        'ui:readonly': true,
        'ui:fieldtype': 'color',
      },
    },
  },
};
