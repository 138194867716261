import { GetServiceQuery, GetServiceQueryVariables } from '../__generated__/graphql';

import GET_SERVICE from '../graphql/getService';
import QueryOneHookData from './@types/QueryOneHookData';
import { flashApolloError } from '../util/errorUtils';
import { useLazyQuery } from '@apollo/client';

type GetServiceData = GetServiceQuery;
type GetServiceVariables = GetServiceQueryVariables;

type UseGetServiceByIdType = QueryOneHookData<GetServiceData['service']>;

export default function useGetServiceById(): UseGetServiceByIdType {
  const [getService, { called, data, error, loading }] = useLazyQuery<
    GetServiceData,
    GetServiceVariables
  >(GET_SERVICE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: flashApolloError,
  });

  return {
    called,
    data: data?.service ?? undefined,
    error,
    loading,
    query: (id: string): void => {
      getService({
        variables: {
          where: { id },
        },
      });
    },
  };
}
