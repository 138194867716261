import AccessGrantAction from '../../enums/AccessGrantAction.enum';
import { AccessGrantPageInfo } from '../../pages/AccessGrant/@types/GetAccessGrantPageInfo';
import { AnyAction } from 'redux';

export const setAccessGrantPageInfo = (payload: AccessGrantPageInfo): AnyAction => ({
  payload,
  type: AccessGrantAction.SET_ACCESS_GRANT_PAGE_INFO,
});

export const clearAccessGrantPageInfo = (): AnyAction => ({
  type: AccessGrantAction.CLEAR_ACCESS_GRANT_PAGE_INFO,
});
