import * as yup from 'yup';

import URL_VALIDATION from '../../../constants/yupValidations/constants/url';
import { validateDecimalPlaces } from '../../../util/numberUtils';

const SERVICE_VALIDATION_SCHEMA = yup.object().shape({
  description: yup.string().required('Description is required'),
  imageType: yup.string().required('Image type is required'),
  imageUrl: URL_VALIDATION,
  location: yup.object().shape({
    label: yup.string().required('Location is required'),
    value: yup.string().required('Location is required'),
  }),
  price: yup
    .number()
    .typeError('Please enter a valid value')
    .min(0, 'Price must be a positive number')
    .test('is-decimal', 'Maximum of two decimal places allowed', (value) =>
      validateDecimalPlaces({ decimalPlacesAllowed: 2, value })
    ),
  title: yup.string().required('Title is required'),
  type: yup.object().shape({
    label: yup.string().required('Type is required'),
    value: yup.string().required('Type is required'),
  }),
  largeDescription: yup.string().nullable(),
});

export default SERVICE_VALIDATION_SCHEMA;
