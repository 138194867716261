import { ImageTypeEnum } from '../../UnitTypeForm/enums/ImageTypeEnum.enum';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { MAX_IMAGE_SIZE } from '../../../util/fileUtil';
import useLoadLocationOptions from '../../../hooks/useLoadLocationOptions';
import useLoadServiceTypeOptions from '../../../hooks/useLoadServiceTypeOptions';
import useSearchLocationsByName from '../../../hooks/useSearchLocations';

interface ServiceImageFormInputProps {
  imageType: string;
}

export default function generateServiceSectionInputs({
  imageType,
}: ServiceImageFormInputProps): InputGroupProps[] {
  let serviceSectionInputs: InputGroupProps[] = [
    {
      label: 'Title',
      name: 'title',
      required: true,
      type: 'text',
    },
    {
      label: 'Search Location',
      loadOptions: useLoadLocationOptions,
      name: 'location',
      query: useSearchLocationsByName,
      type: 'async-select',
    },
    {
      label: 'Image type',
      name: 'imageType',
      options: Object.values(ImageTypeEnum),
      type: 'select',
    },
  ];

  if (imageType === ImageTypeEnum.URL) {
    serviceSectionInputs.push({
      label: 'Image URL',
      name: 'imageUrl',
      required: true,
      type: 'url',
    });
  } else if (imageType === ImageTypeEnum.UPLOAD) {
    serviceSectionInputs.push({
      accept: 'image/*',
      label: 'Image file',
      maxFileSize: MAX_IMAGE_SIZE,
      name: `imageFile`,
      required: true,
      type: 'file',
    });
  }

  serviceSectionInputs = [
    ...serviceSectionInputs,
    {
      label: 'Description',
      name: 'description',
      required: true,
      type: 'text',
    },
    {
      inputMode: 'decimal',
      label: 'Price',
      min: 0,
      name: 'price',
      required: true,
      step: 0.01,
      type: 'number',
    },
    {
      label: 'Types',
      loadOptions: useLoadServiceTypeOptions,
      name: 'type',
      required: true,
      type: 'async-select',
    },
    {
      label: 'Metadata',
      name: 'metadata',
      type: 'textarea',
    },
    {
      label: 'Ask for time',
      name: 'askForTime',
      type: 'checkbox',
    },
    {
      defaultChecked: true,
      label: 'Display comment box',
      name: 'displayCommentBox',
      type: 'checkbox',
    },
    {
      label: 'Large description',
      name: 'largeDescription',
      type: 'textarea',
    },
  ];

  return serviceSectionInputs;
}
